<template>
  <div class="page event-page">
    <img class="img-fluid hero" :src="require(`@/assets/img/restaurant/slider.jpg`)">
    <div class="container-xxl my-4">
      <div class="row d-flex align-items-stretch">
        <div class="col">
          <h1>{{ $t('nav.events') }}</h1>
          <ul class="event-list row d-flex align-items-stretch">
            <li class="col-xl-4 my-3" v-for="(el, index) in events[$i18n.locale]" :key="index" >
              <HoverCard class="h-100" to="/" :title="el.title" :subtitle="el.subtitle" :img="el.img">
                <template #outerbody>
                  <div v-if="el.date" class="event-date px-2 py-1">{{  el.date }}</div>
                </template>
              </HoverCard>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import Carousel from '../ui/Carousel.vue';
import Slide from '../ui/Slide.vue';
import HoverCard from '../ui/card/HoverCard.vue';
import Events from '../../assets/json/events.json';

export default {
  name: 'EventsPage',
  components: {
    HeroCarousel, Carousel, Slide, HoverCard
  },
  data() {
    return {
      events : Events
    }
  }
};
</script>

<style lang="scss" scoped>
.event-list li {
  display: inline-block;
}
.event-date {
  position:absolute;
  top: 0;
  right: 0;
  background: rgba(255,255,255,.9);
  font-weight: 600;
}
</style>
