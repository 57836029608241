import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import store from './store'
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import i18n from './i18n'
import axios from 'axios';
import VueGtag from "vue-gtag";

const token = '118cbb92734c72d39ef7ce32fd93c777ab899a16058852a6f14846e25d4b9da80b25a212206bf3e34b46472910523a746e33781df0f1d3416bfa4a0248eaebfdbc29eaae12f56e9079df44980899b4c1582883f2c4259762524e8ae7f479a692269090be4d68c41cf835538607bb86fa2583e67e1aff07311c6ec922d9c805f4';

axios.defaults.baseURL = 'http://admin.lasource-hautesalpes.com/'
axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
export default axios;

createApp(App).use(i18n).use(store).use(router).use(VueGtag, {
  config: { id: "G-P0WXN60ZSF" }
}).mount('#app')
