<template>
  <div class="page gallery-page">
    <HeroCarousel :src="carouselSlides" />
    <div class="container-xxl v-spacer">
      <div class="row">
        <div class="col">
          <h1>{{ $t('gallery.title') }}</h1>
          <Gallery cols='4'>
          </Gallery>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import Gallery from '../views/Gallery.vue';

export default {
  name: 'GalleryPage',
  components: { HeroCarousel, Gallery },
  data() {
    return {
      carouselSlides: ['home/carousel/drive']
    }
  }
};
</script>

<style>

</style>
