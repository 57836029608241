<template>
  <div class="card">
    <img v-if="img" :src="require(`@/assets/${img}`)" class="card-img-top img-fluid" alt="...">
    <div class="card-body">
      <h5 v-if="title" class="card-title">
        {{ title }}
        <small v-if="subtitle" class="card-subtitle my-1 text-muted d-block">
          {{ subtitle }}
        </small>
      </h5>
      <p class="card-text">
        <slot></slot>
      </p>
      <slot name="button">
        <router-link v-if="to && label" :to="to" class="card-btn btn btn-primary">{{ label }}</router-link>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    img: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.card {
  .card-title {
    font-weight: 600;
  }
  .card-btn {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
}
</style>
