<template>
    <div id="locale-switch" class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <img class="mr-1" :src="require(`@/assets/img/locales/`+$i18n.locale+`.png`)" /> <span class="d-none d-sm-inline">{{ labels[$i18n.locale] }}</span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="(lang, index) in langs" :key="index">
                <a class="dropdown-item py-2" href="#" @click="$i18n.locale = lang"><img class="mr-1" :src="require(`@/assets/img/locales/`+lang+`.png`)" /> {{ labels[lang] }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'LanguageSwitch',
  data() {
      return {
          langs: ["fr", "en", "nl"],
          labels: {
              'en': 'English',
              'fr': 'Français',
              'nl': 'Nederlands'
          }
      }
  }
};
</script>

<style lang="scss">
#locale-switch {
    
    .btn {
        font-weight: bold;
        color: rgba(255, 255, 255, 0.65);
        background: none;
        border: none;
    }
    .btn, a {
        line-height: 19px;
    }
    img {
        width: 16px;
    }
    .dropdown-menu {
        font-weight: bold;
        border: none;
    }
}
</style>