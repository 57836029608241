<template>
  <div class="page not-found-page">
    <HeroCarousel :src="carouselSlides" />
    <div class="container-xxl p-5">
      <div class="row pb-5">
          <div class="col py-5">
              <h1 class="pb-3">{{ $t('notfound.title') }}</h1>
              <p>{{ $t('notfound.text') }} <a href="/">{{ $t('notfound.link') }}</a></p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import Carousel from '../ui/Carousel.vue';
import Slide from '../ui/Slide.vue';

export default {
  name: 'NotFoundPage',
  components: {
    HeroCarousel, Carousel, Slide
  },
  data() {
    return {
      carouselSlides : ['home/carousel/drive','home/carousel/logs','home/carousel/sidebnb','home/carousel/terrace'],
    }
  }
};
</script>

<style lang="scss" scoped>
</style>