<template>
  <div class="card">
    <img v-if="img" :src="require(`@/assets/${img}`)" class="card-img-top img-fluid" alt="...">
    <div class="card-body">
      <h5 v-if="title" class="card-title">
        {{ title }}
        <small v-if="subtitle" class="card-subtitle mt-2 mb-1 text-muted d-block">
          {{ subtitle }}
        </small>
      </h5>
      <p class="card-text">
        <slot></slot>
      </p>
      <slot name="button">
        <router-link v-if="to && label" :to="to" class="card-btn btn btn-primary">{{ label }}</router-link>
      </slot>
    </div>
    <slot name="outerbody"></slot>
  </div>
</template>

<script>
export default {
  name: 'HoverCard',
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    img: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  overflow: hidden;
  cursor: pointer;

  .card-title {
    font-weight: 600;
    color : #33629b;
    small {
      font-size: 16px;
    }
  }
  .card-btn {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
  .card-body {
    position: absolute;
    width: 100%;
    bottom: 50px;
    background : rgba(255,255,255,.9);
    transform: translateY(100%);
    transition: .3s ease-in-out all;
  }
  &:hover {
    .card-body {
      bottom: 0;
      transform: translateX(0);
    }
  }
}
</style>
