export default {
  "keywords": {
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
    "people": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("nb")), " Personnes"])},
    "tents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentes"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "animal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuit"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])},
    "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le petit-déjeuner peut être commandé pour 9 €. Du pain et des croissants peuvent également être commandés pour être emportés."])},
    "freebreakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petit-Déjeuner Inclus"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambres"])},
    "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping"])},
    "glamping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallerie"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenements"])}
  },
  "notfound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page introuvable"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page que vous demandez n'existe pas."])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'accueil"])}
  },
  "home": {
    "plane": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le championnat du monde de planeur ultra-léger s'installe à Aspres-sur-Buëch du 23 juillet au 6 août."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour plus d'informations"])}
    ],
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez en vidéo"])},
    "newDiscover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez notre nouvelle vidéo"])},
    "halloween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez votre Halloween"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue chez La Source !"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situé à proximité des Alpes, La Source se place au nord de la Provence dans la région des Hautes-Alpes. C'est l'endroit idéal pour un séjour reposante ou des vacances bourrées d'action . La région offre une grande variété d'activités de plein air pour chaque saison et le paysage de montagne est vraiment magnifique."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos forêts sont dominées par quelques exemples superbes d'arbres séquoia géants, des cerisiers, des pins , de la chaux , de noix et de beaucoup d'autres. Avec une moyenne de 300 jours ensoleillé par an, La Source est un endroit merveilleux et tranquille pour se détendre et se relaxer."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec des montagnes, des lacs, des rivières et des sentiers , sous un immense ciel bleu​​, les Hautes-Alpes offrent un terrain inexploré d’Europe. Activités et aventures en abondance, la région offre le meilleur des pistes VTT , du cyclisme , des sports nautique et des sports d’hiver disponibles en Europe."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En partenariat avec des entreprises locales et des guides spécialisés , nous pouvons organiser une variété d'activités à faire en sorte que votre séjour soit mémorable et bien exaltante . Du débutant modeste pour les vrais aventuriers , les Hautes-Alpes offrent quelque chose pour tout le monde."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le camping de 25 places est ouvert toute l'année ( Installations complètes de mi-avril à mi-octobre, installations limitées de novembre à mars ) , et notre Chambres d'Hotes et restaurant sont ouverts toute l'année , qui sont également disponibles pour des évenements privés. Donc, si vous êtes à la recherche d'un endroit tranquille, reposante , un séjour plein d'action ou tout simplement un “afternoon tea” ou repas le soir , il suffit de nous contacter."])}
    ],
    "useful": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens Utiles"])},
      "links": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gites de France"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cool Camping"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routard"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Devoluy"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meteo France"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office de Tourisme"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes Alpes"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astronomes"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAG Club"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage Sun Run"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stations de ski La Drome"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Météo La longeagne"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aérodrome d'Aspres-sur-buëch"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aérodrome de Serres"])}
      ]
    },
    "cards": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans un champ de 5,5 hectares , La Source offre des emplacements au soleil et à l'ombre, le tout avec une vue imprenable sur les montagnes."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des Tentes meublés , avec tout ce dont vous avez besoin pour profiter d'une expérience de camping insolite."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre restaurant / terrasse vous offre le petit déjeuner, le “afternoon tea” , des gâteaux faits maison et des plats fraîchement préparés en soirée."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambres"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos chambres joliment décorées sont chaleureux et accueillant, certaines avec vue imprenable sur la montagne."])}
      }
    ]
  },
  "campsite": {
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez notre camping"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert toute l'année"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Par Nuit)"])},
    "reduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Services Réduits)"])},
    "options": {
      "summer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs d'été"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril-Octobre"])}
      },
      "winter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs d'hiver"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre-Mars"])}
      },
      "pitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement"])},
      "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulte"])},
      "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant -10ans"])},
      "electricity6amp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricité 6 amp"])},
      "electricity10amp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricité 10 amp"])},
      "animal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal"])},
      "extratent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petite Tente Supp."])},
      "extracar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voiture supp."])},
      "extrapers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne invité /jour"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de séjour"])}
    },
    "services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WiFi Gratuit"])},
      "shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Douches chaudes & Toilettes"])},
      "bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pain frais / Croissants"])},
      "fridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frigo public gratuit"])},
      "washing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessive à 5€"])},
      "tennis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table ping-pong"])},
      "volley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filet Badminton/Volley"])},
      "picnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bancs Pique-nique"])},
      "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissage d'eau"])},
      "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement des déchets"])},
      "winter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Available in Winter"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réveillez-vous avec le soleil sur les montagnes et des cerf errant dans la brume du matin."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez La Source par notre allée bordée d'arbres et caché derrière les bois, au sein de notre terrain de 2,2 hectares de beaux arbres et de prairies vous pourrez trouver 25 emplacements spacieux situés sur notre camping d'environ 11 000m² qui offrent un endroit tranquille pour se détendre après une longue journée à l'une des nombreuses activités que notre région a à offrir. Jetez un coup d'oeil à notre plan du site."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les clients avec des bébés, nous avons un matelas à langer et une chaise haute. Nous avons également des jeux de société, des raquettes de tennis et une table de ping-pong. Pour les aventuriers, nous avons également 3 vélos. Sous réserve de disponibilité."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le camping La Source est ouvert toute l'année. Pendant le printemps / été (mi-avril à mi-octobre), nous offrons des installations de camping complet. Pendant les mois de novembre à mars, nous serions heureux d'accueillir les caravanes autonomes, nos installations sanitaires seront fermées, mais nous pouvons toujours proposer de l'électricité (6 ou 10 ampères), de l'eau fraîche et l'élimination des déchets."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre champ d'entrée est maintenu comme un espace libre pour tout le monde afin de profiter d'un pique-nique , un match de football , des événements spéciaux ou même l'atterrissage de votre Parapente."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre Restaurant / Terrasse propose des boissons glacées, gâteaux faits maison et des biscuits à déguster avec votre thé / café. Nous servons le petit-déjeuner et le repas du soir fait maison."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au sein de notre établissement nous avons un WC et lavabo PMR accessible 24h/24h."])}
    ],
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan du site"])},
    "planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvez notre planète ! Nous recyclons ! Il y a des poubelles pour le plastique, le verre et le papier. Nous demandons à nos clients de ne pas gaspiller l'eau et de ne pas laisser de déchets sur le camping et nous les encourageons à utiliser des produits écologiques."])},
    "darkSky": {
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez le Camping à Ciel Etoilé"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping à Ciel Etoilé"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous cherchez une expérience de camping vraiment unique, ne cherchez pas plus loin que La Source. Notre camping est situé dans une zone sans pollution lumineuse. Cela signifie que vous pourrez voir le ciel nocturne dans toute sa splendeur, avec des étoiles, des galaxies et des constellations clairement visibles. Mais les avantages de séjourner dans un camping à ciel étoilé vont bien au-delà de la simple observation des étoiles. Continuons à lire, nous explorerons les avantages de camper dans un endroit où le ciel est vraiment sombre."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'un des principaux attraits d'un camping à ciel étoilé est la possibilité de voir les étoiles comme jamais auparavant. À Saint Pierre d'Argençon, nous avons une pollution lumineuse minimale, de sorte que le ciel nocturne devient une toile d'étoiles scintillantes, de planètes et d'autres corps célestes. Vous pourrez voir la Voie lactée, des étoiles filantes et des constellations de manière impossible dans les villes ou d'autres zones à forte pollution lumineuse. Observer les étoiles dans un camping à ciel étoilé est une expérience vraiment impressionnante que vous n'oublierez jamais."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous êtes dans un endroit avec une pollution lumineuse minimale, le cycle de sommeil naturel de votre corps est moins susceptible d'être perturbé. L'exposition à la lumière artificielle la nuit peut interférer avec la production de mélatonine, l'hormone qui régule le sommeil. Lorsque vous campez dans un endroit où la seule lumière vient des étoiles et de la lune, votre corps peut plus facilement se synchroniser avec son rythme circadien naturel. Cela signifie que vous êtes susceptible de mieux dormir et de vous réveiller plus reposé et rafraîchi."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séjourner dans les Hautes Alpes vous permettra de vous connecter plus profondément avec la nature. Sans les distractions de la lumière artificielle, vous pourrez vous immerger pleinement dans la beauté naturelle de la région. Vous pourrez entendre les sons des animaux nocturnes, respirer de l'air frais et ressentir la paix et la tranquillité d'être loin de l'agitation de la civilisation. Cela peut être une excellente façon de se déconnecter de la technologie, de se recentrer et de développer une nouvelle appréciation du monde naturel."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous cherchez une expérience de camping unique et mémorable, réservez un séjour à La Source, un camping à ciel étoilé. Avec une observation des étoiles améliorée, un sommeil amélioré et une appréciation accrue de la nature, c'est une expérience difficile à battre. Alors emportez votre tente, votre sac de couchage ou réservez l'une de nos tentes Glamping et réservez un séjour à La Source pour une aventure inoubliable sous les étoiles."])}
      ],
      "headings": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation des étoiles améliorée"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommeil amélioré"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmentation de l'appréciation de la nature"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez une aventure sous les étoiles à La Source"])}
      ]
    }
  },
  "rooms": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chambre ", _interpolate(_named("id"))])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambres"])},
    "room": [
      {
        
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Personne"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rez de chaussée"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lit 'Kingsize'"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salle d'eau privée"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réveillez-vous avec une vue imprenable sur les montagnes Jouffan et prenez le petit déjeuner sur votre terrasse privée."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une chambre spacieuse au rez de chaussée pour 2 personnes avec un lit king size et salle d’eau avec douche et WC privé."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la chambre : Table et chaises , une armoire et une commode . Linge de lit, serviettes de bains et petit déjeuner sont inclus dans le prix."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des portes-fenêtres offrent un accès supplémentaire avec vue sur la forêt et les montagnes Jouffan."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2/3 Personne"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Personnes"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rez de chaussée"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Personnes"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lit 'Kingsize' & lit superposé"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salle d'eau privée"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une grande chambre familiale au rez de chaussée avec salle d’eau et WC privé."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ya un lit king size , un grand lit superposé , un écran plat TV / DVD avec des films et beaucoup de jeux pour les enfants si vous souhaitez y passer une nuit."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la chambre : Chauffage électrique, table et chaises , une armoire et une commode."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linge de lit, serviettes et petit déjeuner sont inclus dans le prix."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Personne"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première étage"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lit 'Kingsize'"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salle d'eau privée"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une chambre spacieuse au premier étage avec lit king size et WC privé."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une vue imprenable sur la forêt et les montagnes Jouffan."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la chambre : Table et chaises , une armoire et une commode."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linge de lit, serviettes et petit déjeuner sont inclus dans le prix."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Personne"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première étage"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Lits simples"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune salle d'eau"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacieuse chambre au premier étage avec deux lits simples, une table de dressage avec une chaise, armoire et lavabo."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linge de lit, serviettes et petit déjeuner sont inclus dans le prix."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les chambres 4 et 5 peuvent être réservées ensemble comme une chambre familiale pouvant accueillir de 4 à 6 personnes."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Personne"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première étage"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personnes"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lit Double"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salle d'eau séparée"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une grande chambre au premier étage avec un lit double, un lit supplémentaire peut être ajouté sur demande. Canapé confortable, une armoire et un lavabo. Salle d'eau séparée et WC."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linge de lit, serviettes et petit déjeuner sont inclus dans le prix."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les chambres 4 et 5 peuvent être réservées ensemble comme une chambre familiale pouvant accueillir de 4 à 6 personnes."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous."])}
        ]
      }
    ],
    "seasons": {
      "highlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute saison"])},
      "midlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne saison"])},
      "lowlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse saison"])},
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Mai - 31 Mai"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juil - 31 Août"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Dec - 3 Jan"])}
      ],
      "mid": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Fev - 29 Fev"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Avr - 30 Avr"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juin - 30 Juin"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sep - 31 Oct"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Jan - 31 Jan"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Mar - 31 Mar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Nov - 20 Dec"])}
      ]
    }
  },
  "glamping": {
    "nav": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente Cloche 1"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente Cloche 2"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente Cloche 3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente Stargazer 1"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente Stargazer 2"])}
    ],
    "lotus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tente Lotus Belle 'Stargazer' ", _interpolate(_named("id"))])},
    "seasons": {
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet-Août"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril-Juin"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep-Oct"])}
      ]
    },
    "presentation": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cachés dans les bois autour de La Source, vous trouverez nos jolies tentes Sibley Bell et Lotus Belle Stargazer, chacune posée sur une terrasse en bois pour assurer une base plate et stable, chacune avec sa propre vue sur la nature. Nos 3 tentes cloches ont une vue impressionnante sur les montagnes et nos 2 tentes Stargazer sont immergées dans nos magnifiques forêts."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos tentes pour 2, confortablement meublées, offrent toutes de vrais lits doubles et meubles, linge de lit, planchers en bois, armoires de rangement et tables de chevet pour vêtements et objets personnels, tapis, bouilloire, glacière / réfrigérateur électrique, éclairage électrique, barbecue à gaz, table et chaises sur la terrasse et tout ce dont vous avez besoin pour vivre l'expérience alternative du Glamping. Les serviettes et le petit dejeuner ne sont pas inclus dans le prix."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre restaurant / terrasse propose des boissons glacées, des gâteaux et des biscuits faits maison avec votre thé / café. Nous servons également le petit-déjeuner et des repas du soir fraîchement préparés, sur réservation."])}
    ]
  },
  "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au cours de l'été 2016, nous avons ouvert notre petit restaurant pour compléter notre terrasse très populaire avec des vues majestueuses sur les montagnes. Il y a quelque chose de surréaliste à regarder le soleil se coucher derrière les montagnes tout en dégustant votre apéritif ou votre bière froide, vin ou cocktail. Le restaurant propose des petits déjeuners, du thé/café avec des gâteaux faits maison et des plats cuisinés faits maison fraîchement préparés. Notre restaurant est ouvert toute l'année et est disponible pour de petites réceptions privées, etc. Sur réservation uniquement."])},
  "prices": {
    "titles": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs des chambres 2024"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs camping 2024"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs tentes Cloches & Lotus Belle 'StarGazer' 2024"])}
    ],
    "ensuite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre avec salle d'eau privée"])},
    "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre avec salle d'eau séparée"])},
    "twin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre avec un lavabo"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentes Lotus"])},
    "bell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentes Cloches"])},
    "period": {
      "highlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute saison"])},
      "midlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne saison"])},
      "lowlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse saison"])},
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Mai - 31 Mai"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juil - 31 Août"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Dec - 3 Jan"])}
      ],
      "mid": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Fev - 29 Fev"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Avr - 30 Avr"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juin - 30 Juin"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sep - 31 Oct"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Jan - 31 Jan"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Mar - 31 Mar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Nov - 20 Dec"])}
      ]
    }
  },
  "gallery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallerie"])},
    "filters": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités"])},
      "lasource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Source"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région locale"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])}
    }
  },
  "events": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte evenements"])}
  },
  "contact": {
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portable"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Web"])},
    "gps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées GPS"])},
    "by": {
      "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Avion"])},
      "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Voiture"])},
      "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Train"])},
      "airdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre d'Argençon est situé entre Lyon, Grenoble, Chambéry et Marseille, entre 90 minutes - 2 heures de route. Ceux-ci seraient nos aéroports préférés. En outre, un champ sont Nice et Genève."])},
      "cardesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Haute Alpes sont facilement accessible depuis la plupart des directions, nous sommes à environ 10 heures de route de Calais. Le tracé de l'autoroute est relativement simple avec de nombreux points de repos le long du chemin. Entrez Saint Pierre d'Argençon, 05140 en AA autoroute ou ViaMichelin."])},
      "traindesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Eurostar de Londres St Pancras à Paris. Prendre ensuite le TGV de Paris à Grenoble. (environ 3 heures) Prenez ensuite un train local de Grenoble à Aspres-sur-Buêch qui se trouve à 5 km de Saint Pierre d'Argençon."])}
    },
    "useful": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens utiles"])},
      "links": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy Jet"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Airways"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flybe"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bmibaby"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryan Air"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AA"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Michelin"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurostar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGV"])}
      ]
    }
  },
  "footer": {
    "who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui nous sommes"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos Partenaires"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socializez !"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour, nous sommes Paul et Judy, nous avons déménagé en France à partir du Royaume-Uni en 2004 avec nos deux enfants et nous avons choisi cette région pour sa beauté magnifique, l'air frais et le beau temps. La région des Hautes-Alpes est comme une aire de jeux d’activités en été comme en hiver."])}
  }
}