<template>
<div class="container-fluid">
  <div class="row d-flex align-items-center">
    <div class="col-lg-7 col-xl-6">
      <img :src="require(`@/assets/img/rooms/${id}/room-1.jpg`)" class="img-fluid" alt="">
    </div>
    <div class="col-lg-5 col-xl-6">
      <p class="h1">{{ $t('rooms.title', {id: id}) }} <small class="d-lg-block d-xl-inline text-muted">{{ $t('keywords.people', {nb: 2}) }}</small></p>
        <p>{{ $t('rooms.room['+id+'].description[0]')}}</p>
        <p>{{ $t('rooms.room['+id+'].description[1]')}}</p>
        <p>{{ $t('rooms.room['+id+'].description[2]')}}</p>
        <p>{{ $t('rooms.room['+id+'].description[3]')}}</p>
    </div>
    <div class="spacer"></div>
    <div class="col-lg-7 col-xl-6">
      <img :src="require(`@/assets/img/rooms/${id}/room-2.jpg`)" class="img-fluid" alt="">
    </div>
    <div class="col-lg-5 col-xl-6 mt-4 mt-lg-0">
      <div class="row d-flex align-items-stretch">
        <BookingButton
          id="ConstellationButtonContainer2e1e756f-0268-4abb-aa90-94f47ea1f867"
          url="https://reservation.elloha.com/Widget/BookingButton/2e1e756f-0268-4abb-aa90-94f47ea1f867?idoi=2c32ffc4-8242-4523-b49d-965414611b7c"
          :locale="$i18n.locale"
          class="col-12 mb-3">
          </BookingButton>
        <Card :title="$t('keywords.price')" class="text-center col-xl-6">
          <strong>{{ $t('rooms.room['+id+'].people[0]') }}</strong><br>
          {{ $t('rooms.seasons.lowlabel') }} : {{ (prices[id].low[0]).toFixed(2) }}€<br>
          {{ $t('rooms.seasons.midlabel') }} : {{ (prices[id].mid[0]).toFixed(2) }}€<br>
          {{ $t('rooms.seasons.highlabel') }} : {{ (prices[id].high[0]).toFixed(2) }}€<br>
          <strong>{{ $t('rooms.room['+id+'].people[1]') }}</strong><br>
          {{ $t('rooms.seasons.lowlabel') }} : {{ (prices[id].low[1]).toFixed(2) }}€<br>
          {{ $t('rooms.seasons.midlabel') }} : {{ (prices[id].mid[1]).toFixed(2) }}€<br>
          {{ $t('rooms.seasons.highlabel') }} : {{ (prices[id].high[1]).toFixed(2) }}€<br>
          <router-link to="/prices">
            <button type="button" class="btn btn-primary mt-2">
              {{ $t('keywords.details') }}
            </button>
          </router-link>
        </Card>
        <div class="col-xl-6 d-none d-xl-block">
          <img :src="require(`@/assets/img/rooms/${id}/room-3.jpg`)" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Card from '../ui/card/Card.vue';
import Prices from '../../assets/json/prices.json';
import BookingButton from '../ui/BookingButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Room',
  components: { Card, BookingButton },
  props: {
    id: {
      type: [Number, String],
      validator(value) {
        return Number.isInteger(Number(value));
      },
    },
  },
  computed: {
    /*...mapGetters('rooms', {
      room: 'getRoom'
    }),*/
    prices : function() {
      return [
        {}, // Empty room 0
        Prices.rooms.ensuite,
        Prices.rooms.family,
        Prices.rooms.ensuite,
        Prices.rooms.twin,
        Prices.rooms.double,
      ];
    }
  },
  methods: {
    /*...mapActions('rooms', ['fetchRoom'])*/
  },
  async mounted() {
    /*this.fetchRoom('?locale=' + this.$i18n.locale + "&filters[room_number][$eq]=" + this.id);*/
  },
  beforeRouteUpdate(to, from) {
    /*this.fetchRoom('?locale=' + this.$i18n.locale + "&filters[room_number][$eq]=" + to.params.id);*/
  }
};
</script>
