<template>
  <div class="page restaurant-page">
    <img class="img-fluid hero" :src="require(`@/assets/img/restaurant/slider.jpg`)">
    <div class="container-xxl my-4">
      <div class="row d-flex align-items-stretch">
        <div class="col-xl-8">
          <div class="row">
            <div class="col-lg-6">
              <div class="row d-flex align-items-center">
                <div class="col-md-6 col-lg-12">
                  <h1>{{ $t('nav.restaurant') }}</h1>
                  <p>
                    {{ $t('restaurant') }}
                  </p>
                </div>
                <div class="col-md-6 col-lg-12 order-first order-md-last order-lg-first">
                  <Carousel v-slot="{ currentSlide }" :autoplay="true">
                    <Slide v-for="(slide, index) in restaurantSlides" :key="index">
                      <div v-show="currentSlide === index + 1" class="slide-wrapper">
                        <img :src="require(`@/assets/img/${slide.src}.jpg`)" :class="slide.orientation" />
                        <img v-if="slide.orientation == 'portrait'" :src="require(`@/assets/img/${slide.src}.jpg`)" class="bg-img" />
                      </div>
                    </Slide>
                  </Carousel>
                </div> 
              </div>
            </div>
            <div class="col-md-4 mt-4 mt-lg-0 col-lg-6 order-lg-first">
              <img :src="require(`@/assets/img/restaurant/restaurant_1.jpg`)"
              class="img-fluid" alt="">
            </div>
            <div class="mt-4 col-md-4 col-lg-6">
              <img :src="require(`@/assets/img/restaurant/restaurant_2.jpg`)"
              class="img-fluid" alt="">
            </div>
            <div class="mt-4 col-md-4 col-lg-6">
              <img :src="require(`@/assets/img/restaurant/restaurant_4.jpg`)"
              class="img-fluid" alt="">
            </div>
          </div>
        </div>
        <div class="col-xl-4 pt-3 pt-xl-0 d-flex flex-column">
          <div class="h-100 row d-flex align-items-center align-items-xl-stretch">
            <div class="col-sm-7 col-xl-12 align-self-xl-start">
              <img :src="require(`@/assets/img/restaurant/restaurant_3.jpg`)"
              class="img-fluid" alt="">
            </div>
            <div class="col-sm-3 offset-sm-1 offset-xl-0 col-xl-12 pt-xl-5 align-self-xl-end text-center pt-3 pt-sm-0">
              <img :src="require(`@/assets/img/restaurant/restaurant_5.jpg`)"
              class="mt-auto img-fluid" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import Carousel from '../ui/Carousel.vue';
import Slide from '../ui/Slide.vue';

export default {
  name: 'RestaurantPage',
  components: {
    HeroCarousel, Carousel, Slide
  },
  data() {
    return {
      carouselSlides : [
        'restaurant/slider', 
        'home/carousel/drive'
      ],
      restaurantSlides : [
        {src: 'restaurant/slider/restaurant-2', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-3', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-4', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-5', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-6', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-7', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-8', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-9', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-10', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-11', orientation: 'portrait'},
        {src: 'restaurant/slider/restaurant-12', orientation: 'portrait'},
        {src: 'restaurant/slider/restaurant-13', orientation: 'portrait'},
        {src: 'restaurant/slider/restaurant-14', orientation: 'portrait'},
        {src: 'restaurant/slider/restaurant-15', orientation: 'portrait'},
        {src: 'restaurant/slider/restaurant-16', orientation: 'landscape'},
        {src: 'restaurant/slider/restaurant-17', orientation: 'landscape'},
      ]
    }
  }
};
</script>

<style lang="scss" scoped>

.carousel {
  height: 300px;
  img {
    position: absolute;
    z-index: 2;
    &.landscape {
      object-fit: cover;
    }
    &.portrait {
      object-fit: contain;
    }
  
    &.bg-img {
      object-fit: cover;
      opacity: 0.8;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(10px);
    }
  }
}
</style>
