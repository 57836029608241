<template>
  <div id="app">
    <NavigationMenu />
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import NavigationMenu from './components/ui/NavigationMenu.vue';
import Footer from './components/views/Footer.vue';

export default {
  name: 'App',
  components: { NavigationMenu, Footer },
};
</script>
<style lang="scss">
@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

/* RESET STYLES */
#app {
    ul {
      list-style: none;
      padding-left: 0;
    }
    a {
      text-decoration: none;
    }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.btn:active,
.btn:focus,
.btn:focus:active {
  background-image: none;
  outline: 0;
  box-shadow: none !important;
}
.justified {
  text-align: justify;
}
.bold {
  font-weight: 700;
}
.spacer {
  height: 30px;
  width: 100%;
  opacity: 0;
}
.v-spacer {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hero {
  position: relative;
  width: 100%;
  height: 600px;
}
img.hero {
      object-fit: cover;
}
@media screen and (max-width: 759px) { .hero { height: 200px; } }
@media screen and (min-width: 760px) { .hero { height: 238px; } }
@media screen and (min-width: 960px) { .hero { height: 300px; } }
@media screen and (min-width: 1160px) { .hero { height: 362px; } }
@media screen and (min-width: 1360px) { .hero { height: 425px; } }
@media screen and (min-width: 1560px) { .hero { height: 487px; } }
@media screen and (min-width: 1760px) { .hero { height: 550px; } }
@media screen and (min-width: 1920px) { .hero { height: 600px; } }
</style>
