<template>
  <div class="page rooms-page">
    <HeroCarousel :src="carouselSlides" />
    <div class="container-xxl">
      <div class="row d-flex align-items-stretch">
        <div class="col-sm-12 col-md-3 col-lg-2 mt-4">
          <nav id="room-nav" class="navbar navbar-expand-md navbar-light pt-md-0">
            <a class="navbar-brand d-md-none" href="#">{{ $t('rooms.rooms')}}</a>
            <button class="navbar-toggler" type="button"
              data-bs-toggle="collapse"
              data-bs-target="#room-nav-collapse">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div id="room-nav-collapse" class="collapse navbar-collapse">
              <ul>
                <router-link v-for="(id, index) in [1,2,3,4,5]" :key="index" :to="`/rooms/`+id" class="nav-link">
                  <li class="nav-item">
                    <h5>
                      {{ $t('rooms.title', {id: id}) }}
                      <small class="d-block d-xl-inline-block mb-2 text-muted">
                        {{ $t('rooms.room['+id+'].subtitle') }}
                      </small>
                    </h5>
                    <p>
                      <span class="d-none d-md-block">
                        {{ $t('rooms.room['+id+'].content[0]') }}
                        <br>
                        {{ $t('rooms.room['+id+'].content[1]') }}
                        <br>
                        {{ $t('rooms.room['+id+'].content[2]') }}
                      </span>
                    </p>
                  </li>
                </router-link>
              </ul>
            </div>
          </nav>
        </div>
        <div class="col-sm-12 col-md-9 col-lg-10 mb-4 mt-4">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import CardLink from '../ui/card/CardLink.vue';

export default {
  name: 'RoomsPage',
  components: {
    HeroCarousel, CardLink,
  },
  data() {
    return {
      carouselSlides : [
        'rooms/pinecones',
        'rooms/lady',
        'rooms/clock',
        'rooms/breakfast',
        'rooms/candles',
      ],
    }
  }
};
</script>

<style lang="scss">
  .rooms-page{
    #room-nav {
      color: #333;
      .navbar-brand {
        margin-left: 10px;
      }
      .navbar-toggler {
        &:focus {
            background-color: rgba(159, 126, 105, 0.5);

            box-shadow: none;
        }
        border: none;
      }
      a {
        padding: 0;
        li {
          border: none;
          background-color: #E3E7AF;
          color: #333;
          margin-bottom: 0.75rem;
          border-radius: 5px;
          padding: 10px;
          h5 {
            font-weight: 600;
          }
          
          small {
            font-size: 0.85rem;
          }
          p {
            font-size: 0.75rem;
          }

          &:hover {
            box-shadow: 0px 3px 10px rgba(0,0,0, .25);
          }
        }
        &.router-link-active li {
          background-color: #9F7E69;
          color: #fff;
          .text-muted {
            color: #ccc !important;
          }
        }
      }
    }
  }

  @media screen and (max-width:767px) {
    #room-nav {
      background-color: #E3E7AF;
    }
  }
</style>
