<template>
  <div class="page contact-page">
    <HeroCarousel :src="carouselSlides">
    </HeroCarousel>
    <BookingForm
      id="ConstellationWidgetContainer9ac28248-ecc5-46a0-9b82-c90b973305ef"
      url="https://reservation.elloha.com/Widget/BookingEngine/9ac28248-ecc5-46a0-9b82-c90b973305ef?idoi=2c32ffc4-8242-4523-b49d-965414611b7c"
      :locale="$i18n.locale">
    </BookingForm>
    <div class="container-xxl v-spacer">
      <div class="row justify-content-md-center">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-md mb-5">
                <h1 class="mb-3">Contact</h1>
                <p>Judy & Paul SPICER</p>
                <p>
                  SARL "Frenchtracks de La Source"<br>
                  05140 Saint Pierre d'Argençon<br>
                  Hautes-Alpes - France<br>
                </p>
                <p>
                  {{ $t('contact.phone') }} : +33 (0)4 92 58 67 81<br>
                  {{ $t('contact.mobile') }} : +33 (0)6 78 32 30 40
                </p>
                <p>
                  E-mail :
                  <a href="mailto:info@lasource-hautesalpes.com">info@lasource-hautesalpes.com
                  </a>
                  <br>
                  {{ $t('contact.site') }} : <a href="/">www.lasource-hautesalpes.com</a>
                  <br>
                  {{ $t('contact.gps') }} : 44°31’52.47” N - 5°41’02.91” E
                </p>
            </div>
            <div class="col-md pt-3">
              <h4>Directions...</h4>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      {{ $t('contact.by.air') }}
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">{{ $t('contact.by.airdesc') }}</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      {{ $t('contact.by.car') }}
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">{{ $t('contact.by.cardesc') }}</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      {{ $t('contact.by.train') }}
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">{{ $t('contact.by.traindesc') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="col">
            <h2 class="mb-3">{{ $t('contact.useful.title') }}</h2>
            <ul>
              <li v-for="(link, index) in links" :key="index">
                <a target="_blank" href="{{ link }}">{{ $t('contact.useful.links['+index+']') }}</a>
              </li>
              <li>
                <a target="_blank" href="http://www.france-voyage.com/chambres-hotes/chambre-maison-saint-pierre-d-argencon-5100.htm"><img border=0 src="http://www.france-voyage.com/visuals/pro/label.gif" width=150 height=53 title="Chambre d'hôtes dans les Hautes-Alpes"></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2844.2249311973674!2d5.681715151773801!3d44.53102680313739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cae0f9dc4db799%3A0x3b1f5be5259e7799!2sLa%20Source%2C%2005140%20Saint-Pierre-d&#39;Argen%C3%A7on!5e0!3m2!1sfr!2sfr!4v1652975128106!5m2!1sfr!2sfr" 
            width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import BookingForm from '../ui/BookingForm.vue';

export default {
  name: 'ContactPage',
  components: { HeroCarousel, BookingForm },
  data() {
    return {
      carouselSlides: ["home/carousel/drive"],
      links: [
        "http://www.gites-de-france.com",
        "http://www.coolcamping.com",
        "http://www.booking.com",
        "http://www.routard.com",
        "http://www.ledevoluy.com",
        "http://www.meteofrance.com/accueil",
        "http://www.sources-du-buech.com/",
        "http://www.hautes-alpes.net",
        "https://www.kaus-australis.fr/"
      ]
    }
  }
};
</script>

<style>

</style>
