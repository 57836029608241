<template>
    <div class="container-xxl">
        <div class="row justify-content-md-center">
            <div class="col-lg-10 col-xl-8 col-12 booking-wrapper">
                <div :id="id" class="booking-widget"
                    style="width:100%" title="La Source Chambre d&#39;H&#244;te, Restaurant and Camping" 
                    :data-id-projet='idproject'>
                    Chargement en cours...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BookingForm',
  props: {
      id: {
          type: String,
          required: true
      },
      url: {
          type: String,
          required: true
      },
      locale: {
        type: String,
        required: true
      }
  },
  computed : {
    idproject : function() {
      let idp = this.id;

      idp.replace("ConstellationWidgetContainer", "");
      idp.replace("ConstellationButtonContainer", "");
      idp.replace("-", "");

      return idp;
    },
    culture : function() {
      return this.locale + "-" + this.locale.toUpperCase();
    }
  },
  mounted() {
    let script = document.createElement('script');
    script.setAttribute('src', 'https://reservation.elloha.com/Scripts/widget-loader.min.js?v=42');
    script.setAttribute('id', 'elloha-'+this.idproject);
    document.head.appendChild(script);

    let self = this;

    script.onload = function(){
        window["constellationWidgetUrl"+self.idproject] = self.url + "&culture=" + self.culture;
        window["constellationTypeModule"+self.idproject]=1;

        constellationWidgetLoad(self.id);
        constellationWidgetAddEvent(window, "resize", function () { 
            constellationWidgetSetAppearance(self.id); 
        });
    }
  },
  unmounted() {
    let scr = document.querySelector('#elloha-'+this.idproject);
    if(scr) {
      scr.parentElement.removeChild(scr);
    }
  }
};
</script>

<style lang="scss">
.booking-wrapper {
    margin: 30px 0;
    .booking-widget {
        box-shadow: 0px 0px 10px #999;
    }
}
@media screen and (min-width: 960px) {
    .booking-wrapper {
        margin: 0;
        transform: translateY(-30px);
    }
}
</style>