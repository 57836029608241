<template>
  <div class="page camping-page">
    <HeroCarousel :src="carouselSlides">
      <div class="coolcamping-5">
        <img :src="require(`@/assets/img/partners/cc_5_star.png`)" class="img-fluid">
      </div>
    </HeroCarousel>
    <div class="container-xxl v-spacer">
      <router-view @carousel="setCarousel" />
      <!-- Modal -->
      <div class="modal fade" id="planModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('campsite.plan') }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <img :src="require(`@/assets/img/camping/pitch-plan.jpg`)" class="img-fluid" alt="">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('keywords.close') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';

export default {
  name: 'CampingPage',
  components: { HeroCarousel },
  data() {
    return {
      carouselSlides : [
        'camping/slider'
      ],
    }
  },
  methods: {
    setCarousel(_slides) {
      console.log("CAROUSEL");
      this.carouselSlides = _slides;
    }
  }
};
</script>

<style lang="scss">
  .camping-page {
    .card {
      border: none;
    }
    .card-subtitle {
      font-size: 0.8rem;
    }
    .card-body {
      padding: 1rem 0;
    }
    .card-text {

      div {
        text-align: left;
        font-size: 0.8rem;
      }
      p {
        text-align: left;
        font-size: 0.8rem;
        margin-bottom: 0.3rem;
        span {
          color: red;
        }
      }
    }
    .coolcamping-5 {
      position: absolute;
      right: 5px;
      bottom: 5px;
      width: 100px;

    }

    .save-the-planet {
      background-color: rgba(142, 180, 227, 0.2);
      box-shadow: 0px 0px 15px 0px rgba(170,170,170, 0.7);
      font-weight: 700;
      img {
        width: 100px;
      }
    }
  }

</style>
