<template>
  <div class="page glamping-page">
    <HeroCarousel :src="carouselSlides">
      <router-link to="/camping/dark-sky" id="dark-sky-discover-btn">
        <button type="button" class="btn btn-secondary">
          {{ $t('campsite.darkSky.discover') }}
        </button>
      </router-link>
    </HeroCarousel>
    <router-view />
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';

export default {
  name: 'GlampingPage',
  components: {
    HeroCarousel,
  },
  props: {
    id: {
      type: [Number, String],
      validator(value) {
        return Number.isInteger(Number(value));
      },
    },
  },
  data() {
    return {
      carouselSlides : [
        'glamping/slider'
      ]
    }
  }
};
</script>

<style lang="scss">
.glamping-page {
  #dark-sky-discover-btn {
    position: absolute;
    left: 10px;
    bottom: 35px;
  }
  .card-link {
    .card-subtitle {
      display: block;
    }
  }
  .card {
    h6 {
      font-size: 0.9rem;
    }
    font-size: 0.8rem;
  }
  .card-link {
      margin-bottom: 0.75rem;

      border: none;
      background-color: #E3E7AF;
      color: #333;
      .card-subtitle {
        font-size: 0.85rem;
      }
      .card-text {
        font-size: 0.75rem;
      }

      &:hover {
        box-shadow: 0px 3px 10px rgba(0,0,0, .25);
      }
    }
    .router-link-active {
      .card-link {
        background-color: #9F7E69;
        color: #fff;
        .text-muted {
          color: #ccc!important;
        }
      }
    }
}

@media screen and (max-width: 720px) { 
  .glamping-page #dark-sky-discover-btn {
    bottom: -40px;
    left: 5%;
    width: 90%;
    & > button {
      width: 100%;
      font-size: 12px;
    }
  }
  .glamping-page .carousel.hero {
    margin-bottom: 80px;
    overflow: visible;
  }
 }
</style>
