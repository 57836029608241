<template>
  <div class="page home-page">
    <HeroCarousel :src="carouselSlides">
      <button id="discover-btn" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#youtubeModal">
        {{ $t('home.discover') }}
      </button>
      <button id="discover-btn2" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#youtubeModal2">
        {{ $t('home.newDiscover') }}
      </button>
      <router-link to="/camping/dark-sky" id="dark-sky-discover-btn">
        <button type="button" class="btn btn-secondary">
          {{ $t('campsite.darkSky.discover') }}
        </button>
      </router-link>
    </HeroCarousel>
    <BookingForm
      id="ConstellationWidgetContainer5766492a-462d-4640-b81c-45702bb8f440"
      url="https://reservation.elloha.com/Widget/BookingEngine/5766492a-462d-4640-b81c-45702bb8f440?idoi=2c32ffc4-8242-4523-b49d-965414611b7c"
      :locale="$i18n.locale">
    </BookingForm>
    <div class="container-xxl">
      <div class="row">
        <!--div v-if="events[$i18n.locale].length" class="col-12 mt-3">
          <h2>Latest Events</h2>
          <ul class="event-list row d-flex align-items-stretch">
            <li class="col-sm-6 col-lg-3 my-3" v-for="(el, index) in latestEvents" :key="index" >
              <HoverCard class="h-100" to="/" :title="el.title" :subtitle="el.subtitle" :img="el.img">
                <template #outerbody>
                  <div v-if="el.date" class="event-date px-2 py-1">{{  el.date }}</div>
                </template>
              </HoverCard>
            </li>
          </ul>
        </div-->
        <div class="col-md-8">
          <h1 class="mb-4">{{ $t('home.title') }}</h1>
          <div class="row justified">
            <div class="col-sm-12 bold mb-3">
              {{ $t('home.description[0]')}}
            </div>
            <div class="col-sm-6">
              <p>{{ $t('home.description[1]')}}</p>
              <p>{{ $t('home.description[2]')}}</p>
            </div>
            <div class="col-sm-6">
              <p>{{ $t('home.description[3]') }}</p>
              <p>{{ $t('home.description[4]') }}</p>
            </div>
          </div>
        </div>
        <div class="col text-center">
          <h2 class="h1 mb-4">{{ $t('home.useful.title')}}</h2>
          <ul>
            <li><a href="http://www.gites-de-france.com">{{ $t('home.useful.links[0]') }}</a></li>
            <li><a href="http://www.coolcamping.com">{{ $t('home.useful.links[1]') }}</a></li>
            <li><a href="http://www.booking.com">{{ $t('home.useful.links[2]') }}</a></li>
            <li><a href="http://www.routard.com">{{ $t('home.useful.links[3]') }}</a></li>
            <li><a href="http://www.ledevoluy.com">{{ $t('home.useful.links[4]') }}</a></li>
            <li><a href="http://www.meteofrance.com/accueil">{{ $t('home.useful.links[5]') }}</a></li>
            <li><a href="http://www.sources-du-buech.com/">{{ $t('home.useful.links[6]') }}</a></li>
            <li><a href="http://www.hautes-alpes.net">{{ $t('home.useful.links[7]') }}</a></li>
            <li><a href="https://www.kaus-australis.fr/">{{ $t('home.useful.links[8]') }}</a></li>
            <li><a href="http://club.vag.free.fr/">{{ $t('home.useful.links[9]') }}</a></li>
            <li><a href="http://vintagesunrun.org">{{ $t('home.useful.links[10]') }}</a></li>
            <li><a href="http://www.ladromemontagne.fr/">{{ $t('home.useful.links[11]') }}</a></li>
            <li><a href="http://www.balisemeteo.com/balise.php?idBalise=22">{{ $t('home.useful.links[12]') }}</a>
            </li><li><a href="http://www.alpes-envol.fr/pages/aerodromes/aerodrome-d-aspres-sur-buech/">{{ $t('home.useful.links[13]') }}</a></li>
            <li><a href="http://www.alpes-envol.fr/pages/aerodromes/aerodrome-de-la-batie/">{{ $t('home.useful.links[14]') }}</a></li>
            <li><a href="https://www.campercontact.com">Camper Contact</a></li>
            <li><a href="https://park4night.com">Park 4 Night</a></li>
          </ul>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="card-group">
        <div class="col-md-3 col-sm-6">
          <Card :title="$t('home.cards[0].title')" to="/camping" :label="$t('home.cards[0].label')" img="img/home/campsite.jpg" class="h-100">
            {{ $t('home.cards[0].description') }}
            <button type="button" class="btn btn-secondary position-absolute top-0 end-0" style="pointer-events: none">
              {{ $t('campsite.open') }}
            </button>
          </Card>
        </div>
        <div class="col-md-3 col-sm-6">
          <Card :title="$t('home.cards[1].title')" to="/glamping" :label="$t('home.cards[1].label')" img="img/home/glamping.jpg" class="h-100">
              {{ $t('home.cards[1].description') }}
          </Card>
        </div>
        <div class="col-md-3 col-sm-6">
          <Card :title="$t('home.cards[2].title')" to="/restaurant" :label="$t('home.cards[2].label')" img="img/home/restaurant.jpg" class="h-100">
              {{ $t('home.cards[2].description') }}
          </Card>
        </div>
        <div class="col-md-3 col-sm-6">
          <Card :title="$t('home.cards[3].title')" to="/rooms/1" :label="$t('home.cards[3].label')" img="img/home/rooms.jpg" class="h-100">
              {{ $t('home.cards[3].description') }}
          </Card>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="col-12">
        <a class="weatherwidget-io" href="https://forecast7.com/fr/44d525d70/saint-pierre-dargencon/" data-label_1="SAINT-PIERRE-D'ARGENÇON" data-label_2="METEO" data-font="Helvetica" data-icons="Climacons Animated" data-theme="pure" >SAINT-PIERRE-D'ARGENÇON METEO</a>
      </div>
      <div class="spacer"></div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="youtubeModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="">{{ $t('home.discover') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/gHnvDw0Zqjo?controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('keywords.close') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="youtubeModal2" tabindex="-1" aria-labelledby="" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="">{{ $t('home.discover') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/gH2LyBe2HbI?controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('keywords.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import Card from '../ui/card/Card.vue';
import HoverCard from '../ui/card/HoverCard.vue';
import BookingForm from '../ui/BookingForm.vue';

import Events from '../../assets/json/events.json';

export default {
  name: 'HomePage',
  components: {
    HeroCarousel, Card, HoverCard, BookingForm
  },
  data() {
    return {
      currentSlide: 1,
      carouselSlides: ['home/carousel/drive','home/carousel/logs','home/carousel/sidebnb','home/carousel/terrace'],
      events : Events
    }
  },
  computed: {
    latestEvents : function() {
      return Events[this.$i18n.locale].slice(0, 4);
    }
  },
  mounted() {
    let script = document.createElement('script');
    script.setAttribute('src', 'https://weatherwidget.io/js/widget.min.js');
    script.setAttribute('id', 'weatherwidget-io-js');
    document.head.appendChild(script);
  }
};
</script>

<style lang="scss">
#book-halloween {
  background-color: #FF1616;
  position: absolute;
  bottom: 90px;
  color: rgba(255,255,255,0.9);
  right: 50%;
  transform: translateX(50%);
  transition: box-shadow .3s ease-in-out;
  box-shadow: 0px 0px 5px 2px #ff1616;
  font-size: 24px;

  &:hover {
    color: rgba(255,255,255,0.9);
    background-color: #FF1616;
    box-shadow: 0px 0px 15px 5px #ff1616;
  }
  
}
#planeurs {
  background-color: rgba(142, 180, 227, 0.2);
  box-shadow: 0px 0px 15px 0px rgb(170 170 170 / 70%);
  font-weight: 700;

  p {
    margin-bottom: 0;
    padding-left: 10px;
  }


  img {
    width: 80px;
  }
}
.home-page {
    #discover-btn {
      position: absolute;
      bottom: 35px;
      right: 10px;
    }
    #discover-btn2 {
      position: absolute;
      bottom: 90px;
      right: 10px;
    }
    #dark-sky-discover-btn {
      position:absolute;
      bottom: 35px;
      left: 10px;
      width: 200px;
    }
    h1 {
        text-align: left;
    }
    .card {
      border: none;
      margin: 0 5px;
    }
    .card-text {
      margin-bottom: 40px;
    }
}

.btn {
  &.btn-primary {
    background-color: #E3E7AF;
    color: #555;
    border: none;
    border-radius: 0;
    font-weight: 600;
    padding: 0.5rem 1rem;

    &:hover,
    &.active {
      background-color: #9F7E69;
      color: #fff;
    }
  }
  &.btn-secondary {
    background-color: #9F7E69;
    color: #fff;
    border: none;
    border-radius: 0;
    font-weight: 600;
    padding: 0.5rem 1rem;

    &:hover,
    &.active {
      background-color: #E3E7AF;
      color: #555;
    }
  }
}
.event-date {
  position:absolute;
  top: 0;
  right: 0;
  background: rgba(255,255,255,.9);
  font-weight: 600;
}
@media screen and (max-width: 960px) { 
  #book-halloween {
    font-size: 18px;
    bottom: 35px;
  }
  .home-page #discover-btn {
    font-size: 12px;
    bottom: 0;
    right: 0;
  }
  .home-page #discover-btn2 {
    font-size: 12px;
    bottom: 40px;
    right: 0;
  }
  .home-page #dark-sky-discover-btn {
    bottom: 0;
    left: 0;
    & > button {
      font-size: 12px;
    }
  }
 }
 @media screen and (max-width: 720px) { 
  .home-page #discover-btn {
    font-size: 12px;
    bottom: -80px;
    right: 5%;
    width: 42.5%;
  }
  .home-page #discover-btn2 {
    font-size: 12px;
    bottom: -80px;
    left: 5%;
    width: 42.5%;
  }
  .home-page #dark-sky-discover-btn {
    bottom: -40px;
    left: 5%;
    width: 90%;
    & > button {
      width: 100%;
      font-size: 12px;
    }
  }
  .home-page .carousel.hero {
    margin-bottom: 80px;
    overflow: visible;
  }
 }
 @media screen and (max-width: 550px) { 
  .home-page .carousel.hero {
    margin-bottom: 80px;
  }
  .home-page #discover-btn {
    width: 90%;
    bottom: -80px;
  }
  .home-page #discover-btn2 {
    width: 90%;
    bottom: -120px;
  }
  .home-page .carousel.hero {
    margin-bottom: 120px;
  }
  #book-halloween {
    width: 90%;
    right: 5%;
    bottom: 20px;
    transform: translateX(0);
    font-size: 12px;
  }
 }
</style>
