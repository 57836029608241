<template>
    <nav id="primary-nav" class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid justify-content-start">
            <button class="navbar-toggler" type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#primary-nav-collapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <LanguageSwitch />
            <a class="navbar-brand" href="#"><img id="logo" src="../../assets/logo.png" alt="La Source Logo"></a>
            <div id="primary-nav-collapse" class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">{{ $t('nav.home') }}</router-link>
                    </li>
                    <!--li class="nav-item">
                        <router-link to="/events" class="nav-link">{{ $t('nav.events') }}</router-link>
                    </li-->
                    <li class="nav-item">
                        <router-link to="/rooms/1" class="nav-link">{{ $t('nav.rooms') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/camping" class="nav-link">{{ $t('nav.camping') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/glamping" class="nav-link">{{ $t('nav.glamping') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/restaurant" class="nav-link">{{ $t('nav.restaurant') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/prices" class="nav-link">{{ $t('nav.prices') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/gallery" class="nav-link">{{ $t('nav.gallery') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/contact" class="nav-link">{{ $t('nav.contact') }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import LanguageSwitch from './LanguageSwitch.vue';

export default {
  name: 'NavigationMenu',
  components: { LanguageSwitch }
};
</script>

<style lang="scss">
#logo {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
}
#primary-nav {
    background: rgb(51,98,155);
    background: linear-gradient(90deg, rgba(51,98,155,1) 0%, rgba(142,180,227,1) 100%);
    font-weight: bold;

    .navbar-nav {
        padding: 10px 0;
        .nav-link {
            &.router-link-active {
              color: rgba(255, 255, 255, 1);
          }
            color: rgba(255, 255, 255, .65);
        }
    }
    .navbar-toggler {
        &:focus {
            background-color: rgba(142,180,227,1);
            box-shadow: none;
        }
        border: none;
    }
}
@media screen and (max-width:960px) {
    #logo {
        height: 100px;
    }
}
@media screen and (max-width:600px) {
    #logo {
        height: 75px;
    }
}
</style>