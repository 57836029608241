<template>
  <div class="slide">
    <transition :name="transition">
      <slot />
    </transition>
  </div>
</template>
<script>
export default {
  name: 'Slide',
  props: {
    /**
     * P A R A M E T E R S
     */
    transition: {
        type: String,
        required: false,
        default: 'slide',
        validator(value) {
            return ['slide', 'fade'].includes(value);
        },
    }
  }
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: left 1s ease-in-out;
}
.slide-enter-from {
    left: 100%;
}
.slide-leave-to {
  left: -100%;
}
.slide-enter-to,
.slide-leave-from {
  left: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>