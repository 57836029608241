<template>
  <div class="row d-flex align-items-stretch">
    <div class="col-sm-4 col-md-3 col-lg-2 order-last order-sm-first text-center">
      <router-link to="/camping/dark-sky">
        <div style="position:relative">
          <img class="img-fluid" :src="require(`@/assets/img/camping/dark-sky/button.jpg`)">
          <button type="button" class="btn btn-secondary" style="position:absolute;bottom:0;left:0;">
            {{ $t('campsite.darkSky.discover') }}
          </button>
        </div>
      </router-link>
      <button type="button" class="btn btn-secondary mt-3" style="pointer-events: none">
        {{ $t('campsite.open') }}
      </button>
      <Card :title="$t('campsite.options.summer.title')" :subtitle="$t('campsite.options.summer.period')" class="text-center">
        <span class="card-subtitle text-muted">{{ $t('campsite.night') }}</span><br>
        <div v-for="(option, index) in prices" :key="index">
          {{ $t('campsite.options.'+index) }}
          <span class="float-end">{{ (option.high).toFixed(2) }} €</span>
        </div>
      </Card>
      <Card :title="$t('campsite.options.winter.title')" :subtitle="$t('campsite.options.winter.period')" class="text-center">
        <span class="card-subtitle text-muted">{{ $t('campsite.night') }}</span><br>
        <div v-for="(option, index) in prices" :key="index">
          {{ $t('campsite.options.'+index) }}
          <span class="float-end">{{ (option.low).toFixed(2) }} €</span>
        </div>
      </Card>
      <Card :title="$t('campsite.services.title')" class="text-center">
        <p>{{ $t('campsite.services.wifi') }}</p>
        <p>{{ $t('campsite.services.shower') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.bread') }}</p>
        <p>{{ $t('campsite.services.fridge') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.washing') }}</p>
        <p>{{ $t('campsite.services.tennis') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.volley') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.picnic') }}</p>
        <p>{{ $t('campsite.services.water') }}</p>
        <p>{{ $t('campsite.services.waste') }}</p>
      </Card>
    </div>
    <div class="col-sm-8 col-md-9 col-lg-10 order-first order-sm-last">
      <div class="row d-flex align-items-center">
        <div class="col-lg-6">
          <h1>{{ $t('campsite.title') }}</h1>
            <p>{{ $t('campsite.description[0]') }}</p>
            <p>{{ $t('campsite.description[1]') }}</p>
            <p>{{ $t('campsite.description[2]') }}</p>
        </div>
        <div class="col-md-6 pb-4">
          <img :src="require(`@/assets/img/camping/camping-2.jpg`)"
          class="img-fluid float-end" alt="">
        </div>
        <div class="col-md-6 pb-4">
          <img :src="require(`@/assets/img/camping/camping-3.jpg`)" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pb-4">
          <p>{{ $t('campsite.description[3]') }}</p>
          <p>{{ $t('campsite.description[4]') }}</p>
          <p>{{ $t('campsite.description[5]') }}</p>
          <p>{{ $t('campsite.description[6]') }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 offset-md-2 save-the-planet d-flex align-items-center p-2 mb-5">
          <img :src="require(`@/assets/img/camping/planet.png`)">
          <p class="ps-3 mb-0">
            {{ $t('campsite.planet') }}
          </p>
        </div>
      </div>
      <div class="row d-flex align-items-end">
          <div class="col-md-8 pb-4">
            <img :src="require(`@/assets/img/camping/camping-4.jpg`)" class="img-fluid" alt="">
          </div>
          <div class="col-md-4 pb-4">
            <p class="text-center">
              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#planModal">
                {{ $t('campsite.plan') }}
              </button>
            </p>
            <img :src="require(`@/assets/img/camping/camping-5.jpg`)"
            class="img-fluid float-end" alt="">
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import Card from '../../ui/card/Card.vue';
import Prices from '../../../assets/json/prices.json';

export default {
  name: 'Camping',
  components: {Card},
  data() {
    return {
      prices : Prices.campsite
    }
  }
};
</script>
