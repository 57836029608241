import { createStore } from 'vuex'
import roomsModule from './modules/rooms';

export default createStore({
  state: {
    lang: 'fr'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    rooms: roomsModule
  }
})
