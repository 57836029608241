<template>
  <div class="row d-flex align-items-stretch">
    <div class="col-sm-4 col-md-3 col-lg-2 order-last order-sm-first text-center">
      <router-link to="/camping">
        <div style="position:relative">
          <img class="img-fluid" :src="require(`@/assets/img/camping/dark-sky/button_2.jpg`)">
          <button type="button" class="btn btn-secondary" style="position:absolute;bottom:0;left:0;">
            {{ $t('campsite.discover') }}
          </button>
        </div>
      </router-link>
      <button type="button" class="btn btn-secondary mt-3" style="pointer-events: none">
        {{ $t('campsite.open') }}
      </button>
      <Card :title="$t('campsite.options.summer.title')" :subtitle="$t('campsite.options.summer.period')" class="text-center">
        <span class="card-subtitle text-muted">{{ $t('campsite.night') }}</span><br>
        <div v-for="(option, index) in prices" :key="index">
          {{ $t('campsite.options.'+index) }}
          <span class="float-end">{{ (option.high).toFixed(2) }} €</span>
        </div>
      </Card>
      <Card :title="$t('campsite.options.winter.title')" :subtitle="$t('campsite.options.winter.period')" class="text-center">
        <span class="card-subtitle text-muted">{{ $t('campsite.night') }}</span><br>
        <div v-for="(option, index) in prices" :key="index">
          {{ $t('campsite.options.'+index) }}
          <span class="float-end">{{ (option.low).toFixed(2) }} €</span>
        </div>
      </Card>
      <Card :title="$t('campsite.services.title')" class="text-center">
        <p>{{ $t('campsite.services.wifi') }}</p>
        <p>{{ $t('campsite.services.shower') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.bread') }}</p>
        <p>{{ $t('campsite.services.fridge') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.washing') }}</p>
        <p>{{ $t('campsite.services.tennis') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.volley') }}<br><span>{{ $t('campsite.services.winter') }}</span></p>
        <p>{{ $t('campsite.services.picnic') }}</p>
        <p>{{ $t('campsite.services.water') }}</p>
        <p>{{ $t('campsite.services.waste') }}</p>
      </Card>
    </div>
    <div class="col-sm-8 col-md-9 col-lg-10 order-first order-sm-last">
      <div class="row d-flex align-items-center">
        <div class="col-12">
          <h1>{{ $t('campsite.darkSky.title') }}</h1>
          <p>{{ $t('campsite.darkSky.text[0]') }}</p>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-lg-7">
            <h4>{{ $t('campsite.darkSky.headings[0]') }}</h4>
            <p>{{ $t('campsite.darkSky.text[1]') }}</p>
            <div class="row">
              <div class="col-6">
                <img :src="require(`@/assets/img/camping/dark-sky/hidden_moon_2_new.jpg`)" class="img-fluid" alt="">
              </div>
              <div class="col-6">
                <img :src="require(`@/assets/img/camping/dark-sky/astronomers_4.jpg`)" class="img-fluid" alt="">
              </div>
            </div>
            <h4 class="pt-3">{{ $t('campsite.darkSky.headings[1]') }}</h4>
            <p>{{ $t('campsite.darkSky.text[2]') }}</p>
        </div>
        <div class="col-md-5 pb-4">
          <img :src="require(`@/assets/img/camping/dark-sky/portrait_night_sky.jpeg`)"
          class="img-fluid float-end" alt="">
        </div>
        <div class="col-md-5 pb-4">
          <img :src="require(`@/assets/img/camping/dark-sky/portrait_night_sky_2.jpg`)" class="img-fluid" alt="">
        </div>
        <div class="col-lg-7 pb-4">
          <h4>{{ $t('campsite.darkSky.headings[2]') }}</h4>
          <p>{{ $t('campsite.darkSky.text[3]') }}</p>
          <div class="row">
            <div class="col-6">
              <img :src="require(`@/assets/img/camping/dark-sky/astronomers.jpg`)" class="img-fluid" alt="">
            </div>
            <div class="col-6">
              <img :src="require(`@/assets/img/camping/dark-sky/night_sky_lake_new.jpg`)" class="img-fluid" alt="">
            </div>
          </div>
          <h4 class="pt-3">{{ $t('campsite.darkSky.headings[3]') }}</h4>
          <p>{{ $t('campsite.darkSky.text[4]') }}</p>
          <BookingButton
          id="ConstellationButtonContainer2e1e756f-0268-4abb-aa90-94f47ea1f867"
          url="https://reservation.elloha.com/Widget/BookingButton/2e1e756f-0268-4abb-aa90-94f47ea1f867?idoi=2c32ffc4-8242-4523-b49d-965414611b7c"
          :locale="$i18n.locale"
          class="col-12 mb-3">
          </BookingButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '../../ui/card/Card.vue';
import Prices from '../../../assets/json/prices.json';
import BookingButton from '../../ui/BookingButton.vue';

export default {
  name: 'DarkSkyCamping',
  components: {Card, BookingButton},
  data() {
    return {
      prices : Prices.campsite
    }
  },
  init() {
    console.log("THIS IS INIT");
  },
  created() {
    console.log("THIS IS CREATED");
    this.$emit('carousel', ['camping/dark-sky/slider']);
  },
  beforeMounted() {
    console.log("THIS IS BEOFRE MOUNTED");
  },
  mounted() {
    console.log("THIS IS MOUNTED");
  }
};
</script>
