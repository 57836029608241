import axios from 'axios';

export default {
  namespaced: true,
  state: {
    room: {}
  },
  mutations: {
    updateRoom(state, room) {
      console.log("Updating room...");
      console.log(room);

      if(room.data.length) {
        state.room = room.data[0].attributes;
      } else {
        console.log("Unable to update room...");
      }
    },
  },
  actions: {
    async fetchRoom({ commit }, query) {
      axios.get('/api/rooms'+query)
      .then((result) => commit('updateRoom', result.data))
      .catch(console.error);
    }
  },
  getters: {
    getRoom: (state) => state.room
  },
};