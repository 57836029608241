<template>
    <div class="container-xxl my-4 position-relative">
      <div class="row d-flex align-items-stretch">
        <div class="col-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2">
          <h1> {{ $t('glamping.lotus', {id: id}) }} <small class="text-muted">{{ $t('keywords.people', {nb: 2}) }}</small></h1>
        </div>
        <GlampingNavMenu />
        <div class="col-md-9 col-lg-10">
          <div class="row d-flex align-items-stetch">
            <div class="col-sm-4 col-xl-5 pt-sm-3 pt-lg-3 pt-xl-0 text-center">
              <img :src="require(`@/assets/img/glamping/star-${id}/star_1.jpg`)"
              class="img-fluid" alt="">
              <img :src="require(`@/assets/img/glamping/star-${id}/star_4.jpg`)"
            class="d-lg-block d-xl-none pb-4 pb-sm-0 pt-4 pt-lg-5 pt-xl-0 img-fluid" alt="">
            </div>
            <div class="col-sm-8 col-xl-7">
              <img :src="require(`@/assets/img/glamping/star-${id}/star_2.jpg`)"
              class="img-fluid" alt="">
              <img :src="require(`@/assets/img/glamping/star-${id}/star_3.jpg`)"
              class="img-fluid mt-4 pt-lg-4 pt-xl-0" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="position-lg-absolute container-fluid bottom-0 pt-3">
        <div class="row d-flex align-items-center">
          <div class="col-lg-5 col-xl-3">
            <Card :title="$t('keywords.price')" subtitle="" class="text-center">
              <div class="row">
                <div class="col-4 text-start">
                  <h6>{{ $t('keywords.period') }}</h6>
                  <div>{{ $t('glamping.seasons.low[0]') }}</div>
                  <div>{{ $t('glamping.seasons.high[0]') }}</div>
                  <div>{{ $t('glamping.seasons.low[1]') }}</div>
                  <div>{{ $t('keywords.animal') }}</div>
                </div>
                <div class="col-4 text-end">
                  <h6>/{{ $t('keywords.night') }}</h6>
                  <div>{{ (prices.low[0]).toFixed(2) }} €</div>
                  <div>{{ (prices.high[0]).toFixed(2) }} €</div>
                  <div>{{ (prices.low[0]).toFixed(2) }} €</div>
                  <div>{{ (prices.animal[0]).toFixed(2) }} €</div>
                </div>
                <div class="col-4 text-end">
                  <h6>/{{ $t('keywords.week') }}</h6>
                  <div>{{ (prices.low[1]).toFixed(2) }} €</div>
                  <div>{{ (prices.high[1]).toFixed(2) }} €</div>
                  <div>{{ (prices.low[1]).toFixed(2) }} €</div>
                  <div>{{ (prices.animal[1]).toFixed(2) }} €</div>
                </div>
              </div>
            </Card>
          </div>
          <div class="col-sm-3 d-none d-xl-block">
            <img :src="require(`@/assets/img/glamping/star-${id}/star_4.jpg`)"
            class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import GlampingNavMenu from '../../ui/GlampingNavMenu.vue';
import Card from '../../ui/card/Card.vue';
import Prices from '../../../assets/json/prices.json';

export default {
  name: 'StargazerTent',
  components: { GlampingNavMenu, Card },
  props: {
    id: {
      type: [Number, String],
      validator(value) {
        return Number.isInteger(Number(value));
      },
    },
  },
  data() {
    return {
      prices: Prices.glamping
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width:992px) {
  .position-lg-absolute {
    position: absolute;
  }
}
</style>
