<template>
  <div class="pb-4 pb-md-0 col-md-3 col-lg-2">
    <nav id="glamping-nav" class="navbar navbar-expand-md navbar-light pt-md-0">
      <a class="navbar-brand d-md-none" href="#">{{ $t('keywords.tents') }}</a>
      <button class="navbar-toggler" type="button"
        data-bs-toggle="collapse"
        data-bs-target="#glamping-nav-collapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="glamping-nav-collapse" class="collapse navbar-collapse">
        <ul>
          <router-link v-for="(tent, index) in tents" :key="index" :to="tent" class="nav-link">
            <li class="nav-item text-center">
              <h5>
                {{ $t('glamping.nav['+index+']') }}
                <small class="d-block mb-2 text-muted">
                  {{ $t('keywords.people', {nb: 2}) }}
                </small>
              </h5>
              <p>
                <span class="d-none d-md-block"></span>
              </p>
            </li>
          </router-link>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import CardLink from './card/CardLink.vue';

export default {
  name: 'GlampingNavMenu',
  components: { CardLink },
  data() {
    return {
      tents : [
        '/glamping/presentation',
        '/glamping/belltent/1',
        '/glamping/belltent/2',
        '/glamping/belltent/3',
        '/glamping/stargazer/1',
        '/glamping/stargazer/2'
      ]
    }
  }
};
</script>

<style lang="scss">
#glamping-nav {
      color: #333;
      .navbar-brand {
        margin-left: 10px;
      }
      .navbar-toggler {
        &:focus {
            background-color: rgba(159, 126, 105, 0.5);

            box-shadow: none;
        }
        border: none;
      }
      a {
        padding: 0;
        li {
          border: none;
          background-color: #E3E7AF;
          color: #333;
          margin-bottom: 0.75rem;
          border-radius: 5px;
          padding: 10px;
          h5 {
            font-weight: 600;
          }
          
          small {
            font-size: 0.85rem;
          }
          p {
            font-size: 0.75rem;
          }

          &:hover {
            box-shadow: 0px 3px 10px rgba(0,0,0, .25);
          }
        }
        &.router-link-active li {
          background-color: #9F7E69;
          color: #fff;
          .text-muted {
            color: #ccc !important;
          }
        }
      }
    }
  @media screen and (max-width:767px) {
    #glamping-nav {
      background-color: #E3E7AF;
    }
  }
  @media screen and (max-width:1200px) {
    .glamping-nav .card-title {
      font-size: 1rem;
    }
  }
</style>
