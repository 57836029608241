<template>
  <router-link :to="to">
    <Card :title="title" :subtitle="subtitle" class="card-link">
      <slot></slot>
    </Card>
  </router-link>
</template>

<script>
import Card from './Card.vue';

export default {
  name: 'CardLink',
  components: { Card },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
