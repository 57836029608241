<template>
  <div class="gallery-container">
    <div class="gallery-filters d-none d-sm-block">
      <button v-for="(filter, index) in filters" :key="index" @click="filterGallery(filter)" class="gallery-filter btn btn-primary" :class="galleryFilter == filter ? 'active' : ''">
        {{ $t('gallery.filters.'+filter) }}
      </button>
    </div>


    <div id="filters-dropdown" class="dropdown col d-sm-none">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="d-inline-block">{{ $t('gallery.filters.'+galleryFilter) }}</span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="(filter, index) in filters" :key="index">
                <a class="dropdown-item py-2" href="#" @click="filterGallery(filter)">{{ $t('gallery.filters.'+filter) }}</a>
            </li>
        </ul>
    </div>


    <div class="row mx-n5">
      <div v-for="(image,index) in images" :key="index" :class="[nbcols, image.tags.indexOf(galleryFilter) < 0 && galleryFilter != 'all' ? 'disabled' : '']"
        class="gallery-image text-center" :style="galleryStyles">
        <div><!-- Wrapper -->
          <img v-on:click="focusOnImage(index+1)" :src="require(`@/assets/img/`+image.src)" class="img-fluid gallery-img" :class="[`gallery-img-${index+1}`, image.orientation]">
          <img v-if="image.orientation == 'portrait'" v-on:click="focusOnImage(index+1)" :src="require(`@/assets/img/`+image.src)" class="img-fluid bg-img">
        </div>
      </div>
    </div>
    <div v-show="showLb" class="gallery-lb">
      <div class="gallery-lb-wrapper">
        <div class="show-prev-img" v-on:click="focusOnImage(prevId)">
          <div>
            <div class="gallery-arrows">
              <i class="bi bi-caret-left-fill"></i>
            </div>
          </div>
        </div>
        <div class="show-next-img" v-on:click="focusOnImage(nextId)">
          <div>
            <div class="gallery-arrows">
              <i class="bi bi-caret-right-fill"></i>
            </div>
          </div>
        </div>
        <img src="" alt="" class="img-fluid" id="gallery-lb-img">
      </div>
      <button class="gallery-lb-btn btn btn-primary" v-on:click="showLb = false">X</button>
      <div class="gallery-lb-bg" v-on:click="showLb = false"></div>
    </div>
  </div>
</template>
<script>
import Images from '../../assets/json/gallery.json';
export default {
  name: 'Gallery',
  components: { },
  props: {
    cols: {
      type: [Number, String],
      default: 4,
      required: false,
      validator(value) {
        return Number.isInteger(Number(value));
      },
    },
    gutter: {
      type: [Number, String],
      default: 10,
      required: false,
      validator(value) {
        return Number.isInteger(Number(value));
      },
    },
  },
  data() {
    return {
      nbcols: `col-md-${Math.floor(12 / this.cols)} col-sm-${Math.floor(12 / (this.cols-1))} col-${Math.floor(12 / (this.cols-2))}`,
      galleryStyles: {},
      galleryFilter: 'all',
      showLb: false,
      prevId: 0,
      nextId: 0,
      filters: ['all', 'activities', 'lasource', 'local', 'nature', 'food'],
      images: Images
    };
  },
  mounted() {
    const img = document.querySelector('.gallery-image');
    const paImg = {
      top : parseFloat(window.getComputedStyle(img, null).getPropertyValue('padding-top')),
      right : parseFloat(window.getComputedStyle(img, null).getPropertyValue('padding-right')),
      bottom : parseFloat(window.getComputedStyle(img, null).getPropertyValue('padding-bottom')),
      left : parseFloat(window.getComputedStyle(img, null).getPropertyValue('padding-left'))
    }
    this.galleryStyles = {
      height : (img.clientWidth-paImg.left-paImg.right) * 0.75 + paImg.top + paImg.bottom + 'px'
    };
  },
  methods : {
    focusOnImage(id) {
      const img = document.querySelector('.gallery-img-'+id);
      const portrait = img.classList.contains('portrait');
      const lb = document.querySelector('#gallery-lb-img');
      const lbWrapper = document.querySelector('.gallery-lb-wrapper');

      lbWrapper.classList.remove('portrait');
      if(portrait) {
        lbWrapper.classList.add('portrait');
      }

      if(img == null || lb == null) {
        return false;
      }

      if(id == document.querySelectorAll('.gallery-image').length) {
        this.prevId = this.getImg(id-1, -1);
        this.nextId = this.getImg(1, 1);
      } else if(id == 1) {
        this.prevId = this.getImg(document.querySelectorAll('.gallery-image').length, -1);
        this.nextId = this.getImg(id + 1, 1);
      } else {
        this.prevId = this.getImg(id - 1, -1);
        this.nextId = this.getImg(id + 1, 1);
      }
      
      lb.src = img.src;

      this.showLb = true;
    },
    filterGallery(_filter) {
      this.galleryFilter = _filter;
    },
    getImg(id, direction) {
      let oldId = id;
      let img = document.querySelector('.gallery-img-'+id);
      let firstId = 1;
      let lastId = document.querySelectorAll('.gallery-image').length;

      while(img.classList.contains('disabled')) {
        id = parseFloat(id) + parseFloat(direction);

        if(id <= firstId) {
          id = lastId;
        } else if(id >= lastId) {
          id = firstId;
        }

        img = document.querySelector('.gallery-img-'+ id);
      }

      return id;
    }
  }
};
</script>
<style lang="scss">
html,body {
    height:100%;
}
.gallery-container {
  position:relative;
}
.gallery-image {
  padding-top: 15px;
  padding-bottom: 15px;

  transition: all 0.2s;

  div {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }
  img {
    position: absolute;
    top: -9999px;
    right: -9999px;
    bottom: -9999px;
    left: -9999px;
    margin: auto;
    display: inline-block;
    
    z-index: 2;
    transition: all 0.2s;
    width: 100%;
    height: 100%;

    &.landscape {
      object-fit: cover;
    }
    &.portrait {
      object-fit: contain;
    }

    &.bg-img {
    object-fit: cover;
    opacity: 0.8;
    z-index: 1;
    filter: blur(10px);
    }
  }
  &.disabled {
    height: 0px !important;
    padding: 0;
    opacity : 0;
  }
  & img:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}
.gallery-filters {
  height: 50px;
  position: absolute;
  right: 0;
  top: -50px;
}
#filters-dropdown {
    .btn {
        font-weight: bold;
        border: none;
        width: 100%;
        text-align: left;

        span {
          width: 95%;
        }
    }
    .btn, a {
        line-height: 19px;
    }
    .dropdown-menu {
        font-weight: bold;
        width: 100%;
        border: none;
    }
}


/**
  Gallery Lightbox
*/
.gallery-lb {
  
  .gallery-lb-bg {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
  }
  .gallery-lb-wrapper {
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 95%;
    max-height: 95%;
    overflow: hidden;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    &.portrait {
      height: 95%;
      max-height: 95%;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    .gallery-arrows {
      position: absolute;
      height: 100%;
      font-size: 30px;
      line-height: 100px;
      color: white;
      width: 100px;
      opacity: 0.6;
      i {
        display: inline-block;
        height: 100px;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        text-shadow: 0 0 6px #000;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    .show-prev-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      & > div {
        position: relative;
        height: 100%;
        .gallery-arrows {
          top: 0;
          left: 0;
        }
      }
      &:hover {
        i {
          background: radial-gradient(rgb(0 0 0 / 50%) 0%, rgba(0,0,0,0.2) 20%, transparent 50%);
          background-repeat: no-repeat;
        }
        cursor: pointer;
        .gallery-arrows {
          opacity: 1;
        }
      } 
    }
    .show-next-img {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%;  
      & > div {
        position: relative;
        height: 100%;
        .gallery-arrows {
          top: 0;
          right: 0;
        }
      }
      &:hover {
        i {
          background: radial-gradient(rgb(0 0 0 / 50%) 0%, rgba(0,0,0,0.2) 20%, transparent 50%);
          background-repeat: no-repeat;
        }
        cursor: pointer;
        .gallery-arrows {
          opacity: 1;
        }
      }  
    }
  }
  .gallery-lb-btn {
    position: fixed;
    z-index: 9999;
    top: 30px;
    right: 30px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
