<template>
  <div class="container-xxl my-4">
      <div class="row">
        <div class="col-10 offset-md-3 offset-lg-2"><h1>Glamping</h1></div>
        <GlampingNavMenu />
        <div class="col-md-9 col-lg-10">
          <div class="row d-flex align-items-center">
            <div class="col-sm-4 col-md-12 col-lg-4 d-flex align-items-start flex-column">
              <div class="row d-flex align-items-center pb-md-3 pb-lg-0">
                <div class="col-md-6 col-lg-12 pb-sm-3 pb-md-0 pb-lg-3">
                  <img :src="require(`@/assets/img/glamping/glamping_1.jpg`)"
                  class="pb-4 pb-sm-0 img-fluid align-self-start" alt="">
                </div>
                <div class="col-md-6 col-lg-12">
                  <img :src="require(`@/assets/img/glamping/glamping_2.jpg`)"
                  class="pb-4 pb-sm-0 img-fluid mt-auto" alt="">
                </div>
              </div>
            </div>
            <div class="col-sm-8 col-md-12 col-lg-8">
              <img :src="require(`@/assets/img/glamping/glamping_3.jpg`)" class="pb-4 pb-sm-0 img-fluid" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-sm-4">
        <div class="col-sm-3">
          <img :src="require(`@/assets/img/glamping/glamping_4.jpg`)" class="pb-4 pb-sm-0 img-fluid" alt="">
        </div>
        <div class="col-sm-3">
          <img :src="require(`@/assets/img/glamping/glamping_5.jpg`)" class="pb-4 pb-sm-0 img-fluid" alt="">
        </div>
        <div class="col-sm-3">
          <img :src="require(`@/assets/img/glamping/glamping_6.jpg`)" class="pb-4 pb-sm-0 img-fluid" alt="">
        </div>
        <div class="col-sm-3">
          <img :src="require(`@/assets/img/glamping/glamping_7.jpg`)" class="pb-4 pb-sm-0 img-fluid" alt="">
        </div>
      </div>
    </div>
</template>

<script>
import GlampingNavMenu from '../../ui/GlampingNavMenu.vue';

export default {
  name: 'Glamping',
  components: { GlampingNavMenu },
};
</script>
