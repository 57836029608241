<template>
  <div class="container-xxl my-4">
      <div class="row d-flex align-items-stretch">
        <div class="col-12 col-md-9 col-lg-7 offset-lg-2">
          <h1>{{ $t('glamping.nav['+id+']') }} <small class="text-muted">{{ $t('keywords.people', {nb: 2}) }}</small></h1>
        </div>
        <BookingButton
          id="ConstellationButtonContainer2e1e756f-0268-4abb-aa90-94f47ea1f867"
          url="https://reservation.elloha.com/Widget/BookingButton/2e1e756f-0268-4abb-aa90-94f47ea1f867?idoi=2c32ffc4-8242-4523-b49d-965414611b7c"
          :locale="$i18n.locale"
          class="col-12 col-md-3 mb-3 mb-md-0">
        </BookingButton>
        <GlampingNavMenu />
        <div class="col-md-9 col-lg-10">
          <div class="row d-flex align-items-center">
            <div class="col-sm-4 col-md-12 col-lg-4 d-flex align-items-start flex-column">
              <div class="row d-flex align-items-center pb-md-3 pb-lg-0">
                <div class="col-md-6 col-lg-12 pb-3 pb-sm-5 pb-md-0 pb-lg-5">
                  <img :src="require(`@/assets/img/glamping/bell-${id}/bell_1.jpg`)"
                  class="img-fluid align-self-start" alt="">
                </div>
                <div class="col-md-6 col-lg-12 pb-3 pb-sm-0">
                  <img :src="require(`@/assets/img/glamping/bell-${id}/bell_2.jpg`)"
                  class="img-fluid mt-auto" alt="">
                </div>
              </div>
            </div>
            <div class="col-sm-8 col-md-12 col-lg-8">
              <img :src="require(`@/assets/img/glamping/bell-${id}/bell_3.jpg`)"
                class="img-fluid" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 d-flex align-items-center">
        <div class="col-sm-12 col-md-5 pb-3 pb-md-0">
          <Card :title="$t('keywords.price')" subtitle="" class="text-center">
              <div class="row">
                <div class="col-4 text-start">
                  <h6>{{ $t('keywords.period') }}</h6>
                  <div>{{ $t('glamping.seasons.low[0]') }}</div>
                  <div>{{ $t('glamping.seasons.high[0]') }}</div>
                  <div>{{ $t('glamping.seasons.low[1]') }}</div>
                  <div>{{ $t('keywords.animal') }}</div>
                </div>
                <div class="col-4 text-end">
                  <h6>/{{ $t('keywords.night') }}</h6>
                  <div>{{ (prices.low[0]).toFixed(2) }} €</div>
                  <div>{{ (prices.high[0]).toFixed(2) }} €</div>
                  <div>{{ (prices.low[0]).toFixed(2) }} €</div>
                  <div>{{ (prices.animal[0]).toFixed(2) }} €</div>
                </div>
                <div class="col-4 text-end">
                  <h6>/{{ $t('keywords.week') }}</h6>
                  <div>{{ (prices.low[1]).toFixed(2) }} €</div>
                  <div>{{ (prices.high[1]).toFixed(2) }} €</div>
                  <div>{{ (prices.low[1]).toFixed(2) }} €</div>
                  <div>{{ (prices.animal[1]).toFixed(2) }} €</div>
                </div>
              </div>
            </Card>
        </div>
        <div class="col-sm pb-3 pb-sm-0">
          <img :src="require(`@/assets/img/glamping/bell-${id}/bell_4.jpg`)"
            class="img-fluid" alt="">
        </div>
        <div class="col-sm pb-3 pb-sm-0">
          <img :src="require(`@/assets/img/glamping/bell-${id}/bell_5.jpg`)"
            class="img-fluid" alt="">
        </div>
        <div class="col-sm pb-3 pb-sm-0">
          <img :src="require(`@/assets/img/glamping/bell-${id}/bell_6.png`)"
            class="img-fluid" alt="">
        </div>
      </div>
    </div>
</template>

<script>
import GlampingNavMenu from '../../ui/GlampingNavMenu.vue';
import Card from '../../ui/card/Card.vue';
import Prices from '../../../assets/json/prices.json';
import BookingButton from '../../ui/BookingButton.vue';

export default {
  name: 'BellTent',
  components: { GlampingNavMenu, Card, BookingButton },
  props: {
    id: {
      type: [Number, String],
      validator(value) {
        return Number.isInteger(Number(value));
      },
    },
  },
  data() {
    return {
      prices: Prices.glamping
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
