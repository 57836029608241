import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../components/pages/HomePage.vue';
import EventsPage from '../components/pages/EventsPage.vue';
import RoomsPage from '../components/pages/RoomsPage.vue';
import CampingPage from '../components/pages/CampingPage.vue';
import GlampingPage from '../components/pages/GlampingPage.vue';
import RestaurantPage from '../components/pages/RestaurantPage.vue';
import PricesPage from '../components/pages/PricesPage.vue';
import GalleryPage from '../components/pages/GalleryPage.vue';
import ContactPage from '../components/pages/ContactPage.vue';
import NotFoundPage from '../components/pages/NotFoundPage.vue';

import Room from '../components/views/Room.vue';

import Camping from '../components/views/camping/Camping.vue';
import DarkSkyCamping from '../components/views/camping/DarkSkyCamping.vue';
import Glamping from '../components/views/glamping/Glamping.vue';
import GlampingPresentation from '../components/views/glamping/GlampingPresentation.vue';
import BellTent from '../components/views/glamping/BellTent.vue';
import StargazerTent from '../components/views/glamping/StargazerTent.vue';
import i18n from "@/i18n"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/events',
    name: 'Events',
    component: EventsPage,
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: RoomsPage,
    children: [
      {
        path: ':id',
        name: 'RoomsId',
        component: Room,
        props: true,
        beforeEnter(to, from, next) {
          const isValidId = Number.isInteger(Number(to.params.id))
            && Number(to.params.id) >= 1
            && Number(to.params.id) <= 5;

          next(isValidId);
        },
      },
    ],
  },
  {
    path: '/glamping',
    name: 'Glamping',
    component: GlampingPage,
    children: [
      {
        path: '',
        name: 'GlampingHome',
        component: Glamping,
      },
      {
        path: 'presentation',
        name: 'GlampingPresentation',
        component: GlampingPresentation,
      },
      {
        path: 'belltent/:id',
        name: 'GlampingBelltent',
        component: BellTent,
        props: true,
        beforeEnter(to, from, next) {
          const isValidId = Number.isInteger(Number(to.params.id))
            && Number(to.params.id) >= 1
            && Number(to.params.id) <= 3;

          next(isValidId);
        },
      },
      {
        path: 'stargazer/:id',
        name: 'GlampingStargazer',
        component: StargazerTent,
        props: true,
        beforeEnter(to, from, next) {
          const isValidId = Number.isInteger(Number(to.params.id))
            && Number(to.params.id) >= 1
            && Number(to.params.id) <= 2;

          next(isValidId);
        },
      },
    ],
  },
  {
    path: '/camping',
    name: 'Camping',
    component: CampingPage,
    children: [
      {
        path: '',
        name: 'Camping',
        component: Camping,
      },
      {
        path:'dark-sky',
        name:'DarkSkyCamping',
        component: DarkSkyCamping
      }
    ]
  },
  {
    path: '/restaurant',
    name: 'Restaurant',
    component: RestaurantPage,
  },
  {
    path: '/prices',
    name: 'Prices',
    component: PricesPage,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: GalleryPage,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage,
  },
  { 
    path: '/404', 
    name: 'NotFound', 
    component: NotFoundPage, 
  },  
  { 
    path: '/:catchAll(.*)', 
    name: 'Wildcard',
    redirect: '/404',
  }  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top

    if((from.fullPath.indexOf("glamping") > -1 && to.fullPath.indexOf("glamping") > -1) 
      || (from.fullPath.indexOf("rooms") > -1 && to.fullPath.indexOf("rooms") > -1)) {
        return {};
    }
    return { top: 0 }
  },
  routes
})

export default router
