<template>
    <div class="carousel">
      <slot :currentSlide="currentSlide"/>
    </div>
</template>
<script>
/*
Inspired by
https://github.com/johnkomarnicki/vue-3-carousel
*/
export default {
  name: 'Carousel',
  props: {
    /**
     * P A R A M E T E R S
     */
    slideDuration: {
      type: [Number, String],
      required: false,
      default: 5000
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      currentSlide: 1,
      autoplayInterval: null,
      slideCount: 0
    }
  },
  mounted() {
    this.slideCount = document.querySelectorAll('.slide').length;

    if(this.autoplay) {
      this.autoplayInterval = setInterval(() => {
        this.next();
      }, this.slideDuration);
    }
  },
  unmounted() {
    clearInterval(this.autoplayInterval);
  },
  methods: {
    next() {
      if(this.currentSlide === this.slideCount) {
        this.currentSlide = 1;
        return;
      }
      this.currentSlide++;
    },
    prev() {
      if(this.currentSlide === 1) {
        this.currentSlide = this.slideCount;
        return;
      }
      this.currentSlide--;
    }
  }
};
</script>

<style lang="scss">
  .carousel {
    overflow: hidden;
  }
  .slide-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
