<template>
  <div class="container-xxl my-4">
      <div class="row">
        <div class="col-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2">
          <h1>Glamping <small class="text-muted">{{ $t('glamping.nav[0]') }}</small></h1>
        </div>
        <GlampingNavMenu />
        <div class="col-md-9 col-lg-10">
          <div class="row">
            <div class="col-sm-7 col-lg-6">
              <p>
                {{ $t('glamping.presentation[0]') }}
              </p>
              <p>
                {{ $t('glamping.presentation[1]') }}
              </p>
              <p>
                {{ $t('glamping.presentation[2]') }}
              </p>
            </div>
            <div class="col-sm-5 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3">
              <img :src="require(`@/assets/img/glamping/presentation/pres_1.jpg`)"
              class="img-fluid" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-6 col-lg-3 pb-3 pb-sm-0">
          <img :src="require(`@/assets/img/glamping/presentation/pres_2.jpg`)"
          class="img-fluid" alt="">
        </div>
        <div class="col-sm-6 col-lg-3 pb-3 pb-sm-0">
          <img :src="require(`@/assets/img/glamping/presentation/pres_3.jpg`)"
          class="img-fluid" alt="">
        </div>
        <div class="col-sm-6 col-lg-3 pb-3 pb-sm-0 pt-sm-4 pt-lg-0">
          <img :src="require(`@/assets/img/glamping/presentation/pres_4.jpg`)"
          class="img-fluid" alt="">
        </div>
        <div class="col-sm-6 col-lg-3 pb-3 pb-sm-0 pt-sm-4 pt-lg-0">
          <img :src="require(`@/assets/img/glamping/presentation/pres_5.jpg`)"
          class="img-fluid" alt="">
        </div>
      </div>
    </div>
</template>

<script>
import GlampingNavMenu from '../../ui/GlampingNavMenu.vue';

export default {
  name: 'GlampingPresentation',
  components: { GlampingNavMenu },
};
</script>
