<template>
  <Carousel class="hero" v-slot="{ currentSlide }" :autoplay="true">
    <Slide v-for="(slide, index) in src" :key="index">
      <div v-show="currentSlide === index + 1" class="slide-wrapper">
        <img :src="require(`@/assets/img/${slide}.jpg`)" />
      </div>
    </Slide>
    <slot></slot>
  </Carousel>
</template>

<script>
import Carousel from '../ui/Carousel.vue';
import Slide from '../ui/Slide.vue';
export default {
  name: 'HeroCarousel',
  components: {
    Carousel, Slide
  },
  props: {
    src: {
      type: Array,
      required: true,
      default: []
    }
  },
};
</script>

<style lang="scss">
  .slide-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

</style>
