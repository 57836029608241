export default {
  "keywords": {
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
    "people": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("nb")), " People"])},
    "tents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tents"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "animal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
    "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast can be ordered for €9. Bread and croissants can also be ordered to take away."])},
    "freebreakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast Included"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campsite"])},
    "glamping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])}
  },
  "notfound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you're looking for doesn't exist."])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Home"])}
  },
  "home": {
    "plane": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ultra-light glider world championship will be held in Aspres-sur-Buëch from 23 July to 6 August."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here for more information"])}
    ],
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover on film"])},
    "newDiscover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch our new film"])},
    "halloween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book your Halloween"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to La Source !"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Located against the stunning backdrop of the French Alpes, La Source nestles on the northern borders of Provence in the Hautes Alpes region of France. The Hautes Alpes is the ideal location for a relaxing break or action packed activity holiday. The region offers a huge variety of outdoor activities for each season and the mountain scenery is truly awe inspiring."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our woodlands are dominated by some superb examples of giant sequoia trees, cherry, pine, lime, walnut and many others and with an average of 300 days of sunshine a year makes La Source a wonderful and tranquil place to relax and unwind."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With mountains, lakes, rivers and trails under a vast and often cloudless sky, the French Hautes Alpes offers one of Europe’s undiscovered outdoor playgrounds. Activities and adventures abound and the region offers some of the best mountain biking, cycling, winter and water sports available in Europe."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In partnership with local companies and expert guides we can arrange a variety of activities to ensure that your stay is a memorable and thoroughly exhilarating one. From the modest beginner to the truly adventurous, the Hautes Alpes offers something for everyone."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 25 pitch camp site is open all year (full facilities mid April until mid October, limited November to March), our Chambres d’Hotes and Restaurant are open all year round, which are also available for private functions. So if you’re looking for a tranquil, relaxing break, an action packed holiday or simply an afternoon tea or evening meal, just contact us."])}
    ],
    "useful": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Links"])},
      "links": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gites de France"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cool Camping"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routard"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Devoluy"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meteo France"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourist Office"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes Alpes"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astronomy"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAG Vintage Car Club"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage Sun Run"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Drome ski stations"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La longeagne weather"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspres-sur-buëch aerodrome"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serres aerodrome"])}
      ]
    },
    "cards": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campsite"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set in 5.5 acres, La Source ‘Aire Naturelle’ offers both open and shady pitches, all with stunning mountain views."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping in our furnished Tents, with everything you need to enjoy an alternative camping experience."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our own restaurant / terrace offering breakfast, afternoon tea, homemade cakes and freshly prepared evening meals."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B & B"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our beautifully appointed bedrooms are warm and welcoming, some with stunning mountain views."])}
      }
    ]
  },
  "campsite": {
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Our Campsite"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open all year"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Per Night)"])},
    "reduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Reduced Services)"])},
    "options": {
      "summer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Prices"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April-October"])}
      },
      "winter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Prices"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November-March"])}
      },
      "pitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitch"])},
      "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult"])},
      "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infant under 10"])},
      "electricity6amp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity 6 amp"])},
      "electricity10amp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity 10 amp"])},
      "animal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal"])},
      "extratent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small extra tent"])},
      "extracar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Supplement"])},
      "extrapers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor per day"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourist tax"])}
    },
    "services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free WiFi Access"])},
      "shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Hot Showers & Toilets"])},
      "bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fresh Bread / Croissants"])},
      "fridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Public Fridge/Freezer"])},
      "washing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing Machine 5€"])},
      "tennis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table Tennis"])},
      "volley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badminton/Volley Ball Net"])},
      "picnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picnic Benches"])},
      "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Top Up"])},
      "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waste Disposal"])},
      "winter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Available in Winter"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campsite"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wake up to the sun rising over the mountains highlighting deer roaming in the morning mist."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter La Source through our tree-lined driveway and hidden behind mature woods, within our 2.2 hectares of beautiful trees and grasslands you'll find 25 spacious pitches located on our approximately 11,000m² campsite offering a tranquil place to relax after a long day taking part in one of the numerous activities our mountainous region has to offer. Take a look at our site plan."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For guests with babies, we have a baby changing mat and a high chair. We also have board games, tennis rackets and a ping-pong table. For the adventurous, we also have 3 bikes. Subject to availability."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Source campsite is open all year round. During the Spring/Summer (mid April until mid October) we offer full campsite facilities. During November to March we’d gladly welcome self sufficient Motorhomes and Caravans, our Toilet and Shower blocks will be closed but we can still offer electricity (6 or 10 amp), fresh water top up, waste disposal."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our front field is kept as a free space for everyone to enjoy - a picnic, a game of football, special events or even landing your Parapente."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Restaurant / Terrace offers Ice cold drinks, homemade cakes and biscuits to have with your Tea/Coffee. We also serve breakfast and freshly prepared Evening Meals."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We also have toilets for people with reduced mobility, accessible 24/24h."])}
    ],
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Plan"])},
    "planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save our Planet! We recycle! There are bins for plastic, glass and paper; We ask guests not to waste water or leave rubbish on the campsite and we encourage peps to use eco products."])},
    "darkSky": {
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Dark Sky Camping"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Sky Camping"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you're looking for a truly unique camping experience, look no further than La Source. Our campsite is located in an area with no light pollution. This means you'll be able to see the night sky in all its glory, with stars, galaxies, and constellations clearly visible. But the benefits of staying at a dark sky campsite go far beyond just stargazing. Continue reading, we'll explore the advantages of camping in a place where the sky is truly dark."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the main attractions of a dark sky campsite is the opportunity to see the stars like never before. At Saint Pierre d’Argençon, we have minimal light pollution so the night sky becomes a canvas of twinkling stars, planets, and other celestial bodies. You'll be able to see the Milky Way, shooting stars, and constellations in a way that's impossible in cities or other areas with high levels of light pollution. Stargazing at a dark sky campsite is a truly awe-inspiring experience that you'll remember for a lifetime."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you're in a place with minimal light pollution, your body's natural sleep cycle is less likely to be disrupted. Exposure to artificial light at night can interfere with the production of melatonin, the hormone that regulates sleep. When you're camping in a place where the only light is from the stars and moon, your body can more easily sync up with its natural circadian rhythm. This means you're likely to sleep better and wake up feeling more rested and refreshed."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staying in the Hautes Alpes will allow you to connect more deeply with nature. Without the distractions of artificial light, you'll be able to fully immerse yourself in the natural beauty of the area. You'll be able to hear the sounds of nocturnal animals, breathe in fresh air, and feel the peace and tranquillity of being away from the hustle and bustle of civilization. This can be a great way to disconnect from technology, center yourself, and gain a new appreciation for the natural world."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you're looking for a unique and memorable camping experience, consider staying at a dark sky campsite, like La Source. With enhanced stargazing, improved sleep, and increased appreciation for nature, it's an experience that's hard to beat. So pack your tent, grab your sleeping bag or reserve one of our Glamping tents and book a stay at La Source for an unforgettable adventure under the stars."])}
      ],
      "headings": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enchanced Stargazing"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improved Sleep"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased Appreciation for Nature"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a Dark Sky Adventure at La Source"])}
      ]
    }
  },
  "rooms": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Room ", _interpolate(_named("id"))])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "room": [
      {
        
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Person"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ground Floor"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingsize Bed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensuite"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wake up to stunning views of the Jouffan Mountains and take breakfast on your own private terrace."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacious ground floor room for 2 people with king size double bed and en-suite shower and private toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table and chairs, wardrobe and chest of drawers. Bed linen, towels and breakfast are included in the price."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French windows offer additional access with views of the forest and the Jouffan Mountains towering above La Source."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2/3 People"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 People"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ground Floor"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 People"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingsize Bed & Bunk Bed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensuite"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large ground floor family room with en-suite and private toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a Kingsize double bed, large bunk bed, a flat screen TV/DVD with films and plenty of children’s games should you wish to spend a night in."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric heating, table and chairs, wardrobe and chest of drawers."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed linen, towels and breakfast are included in the price."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Person"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Floor"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingsize Bed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensuite"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacious first floor ensuite room with kingsize double bed and private toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunning views of the forest and the Jouffan Mountains towering above La Source."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric heating, table and chairs, wardrobe and chest of drawers."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed linen, towels and breakfast are included in the price."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Person"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Floor"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Single Beds"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Toilet"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacious first floor room with two single beds, dressing table/chair, wardrobe and wash basin."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed linen, towels and breakfast are included in the price."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room 4 and 5 can be booked together as a family room sleeping 4 to 6 people."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Person"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Floor"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 People"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Bed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seperate Shower Room"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large first floor room with double bed, an additional single bed can be added on request. comfortable sofa, wardrobe and wash basin. Separate shower room and toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed linen, towels and breakfast are included in the price."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room 4 and 5 can be booked together as a family room sleeping 4 to 6 people."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us."])}
        ]
      }
    ],
    "seasons": {
      "highlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High season"])},
      "midlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mid season"])},
      "lowlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low season"])},
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 May - 31 May"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 July - 31 Aug"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Dec - 3 Jan"])}
      ],
      "mid": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Feb - 29 Feb"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Apr - 30 Apr"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 June - 30 June"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sep - 31 Oct"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Jan - 31 Jan"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Mar - 31 Mar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Nov - 20 Dec"])}
      ]
    }
  },
  "glamping": {
    "nav": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bell Tent 1"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bell Tent 2"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bell Tent 3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stargazer Tent 1"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stargazer Tent 2"])}
    ],
    "lotus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lotus Belle 'Stargazer' Tent ", _interpolate(_named("id"))])},
    "seasons": {
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July-August"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April-June"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep-Oct"])}
      ]
    },
    "presentation": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nestled into the woodlands around La Source you’ll find our beautifully appointed Sibley Bell Tents and Lotus Belle Stargazer Tents, each sitting on wooden decking to ensure a flat stable base - each with its own individual view of nature. Our 3 bell tents have an impressive mountain view and our 2 Stargazers are immerged in our beautiful forests."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our comfortably furnished Bell Tents for 2, all offer real double beds and furniture, bed linen, wooden flooring, storage and bedside cabinets for clothing and personal items, rugs, kettle, electric cool box/fridge, electric lighting, gas BBQ, table and chairs on the terrace and everything you need to enjoy an alternative Glamping experience. Towels and breakfast not included in the price."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Restaurant / Terrace offers Ice cold drinks, homemade cakes and biscuits to have with your Tea/Coffee. We also serve breakfast and freshly prepared Evening Meals, reserved in advance."])}
    ]
  },
  "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the summer of 2016 we opened our own small restaurant to complement our very popular terrace with stunning majestic mountain views. There’s something surreal about watching the sun go down behind the mountains whilst enjoying your aperitif or ice cold beer, wine or  cocktail. The Restaurant offers breakfasts, tea/coffee with homemade cakes and freshly prepared home cooked evening meals. Our restaurant is open all year round, and available for small private functions etc. By reservation only."])},
  "prices": {
    "titles": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed & Breakfast Prices 2024"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campsite Prices 2024"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bell Tents & Lotus Belle 'StarGazer' Prices 2024"])}
    ],
    "ensuite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room with bathroom (Ensuite)"])},
    "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room with bathroom (NOT Ensuite)"])},
    "twin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room with a washbasin"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lotus Tents"])},
    "bell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bell Tents"])},
    "period": {
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 May - 31 May"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 July - 31 Aug"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Dec - 3 Jan"])}
      ],
      "mid": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Feb - 29 Feb"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Apr - 30 Apr"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 June - 30 June"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sep - 31 Oct"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Jan - 31 Jan"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Mar - 31 Mar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Nov - 20 Dec"])}
      ]
    }
  },
  "gallery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "filters": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "lasource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Source"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Area"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])}
    }
  },
  "events": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event txt"])}
  },
  "contact": {
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "gps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS coordinates"])},
    "by": {
      "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Air"])},
      "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Car"])},
      "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Train"])},
      "airdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre d'Argençon is centrally located between Lyon, Grenoble, Chambery and Marseille, between 90mins - 2 hours away. These would be our preferred airports. Further a field are Nice and Geneva."])},
      "cardesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Haute Alpes is easy to reach from most directions, we are approximately 10 hours drive from Calais. The motorway route being relatively straight forward with many rest points along the way. Enter Saint Pierre d'Argençon, 05140 into AA Autoroute or ViaMichelin."])},
      "traindesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurostar from London St Pancreas to Paris and then get the TGV from Paris to Grenoble. (approx 3 hours) Then take a local train from Grenoble to Aspres-sur-Buêch which is 5km away from Saint Pierre d'Argençon."])}
    },
    "useful": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Links"])},
      "links": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy Jet"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Airways"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flybe"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bmibaby"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryan Air"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AA"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Michelin"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurostar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGV"])}
      ]
    }
  },
  "footer": {
    "who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who we are"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Parters"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socialize !"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi we're Paul and Judy, we moved to France from the UK in 2004 with our two children and chose this region for its stunning beauty, fresh air and great weather. The Hautes Alpes is like a ready made adventure playground with so many activities in both summer and winter."])}
  }
}