<template>
  <div class="page prices-page">
    <HeroCarousel :src="carouselSlides" />
    <div class="container-xxl my-4">
      <h1>
        {{ $t('prices.titles[0]') }}
        <small class="d-block d-md-inline text-muted"> /{{ $t('keywords.night') }}</small>
        <small class="d-block text-muted text-end breakfastText">{{ $t('keywords.freebreakfast') }}</small>
      </h1>
      <table class="shadow p-3 mb-5 bg-body rounded table table-striped table-borderless table-hover text-center">
        <tbody>
          <tr>
            <td class="table-secondary">
              {{ $t('rooms.seasons.lowlabel') }}
            </td>
            <td class="table-primary">
              {{ $t('rooms.seasons.midlabel') }}
            </td>
            <td class="table-success">
              {{ $t('rooms.seasons.highlabel') }}
            </td>
          </tr>
        </tbody>
      </table>
      <h3 class="mt-5 mt-md-3">
       {{ $t('prices.ensuite') }}
      </h3>
      <div class="table-responsive-md">
        <table class="shadow p-3 mb-5 bg-body rounded table table-striped table-borderless table-hover text-center">
          <thead>
            <tr>
              <th>{{ $t('keywords.period') }}</th>
              <th>1 {{ $t('keywords.person') }}</th>
              <th>{{ $t('keywords.people', {nb: 2}) }}</th>
              <th>{{ $t('keywords.people', {nb: 3}) }}</th>
              <th>{{ $t('keywords.people', {nb: 4}) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prd,index) in period" :key="index">
              <td :class="prd.cls">{{ $t('prices.period.'+prd.type+'['+prd.idx+']') }}</td>
              <td v-for="(price,priceIndex) in prices.rooms.ensuite[prd.type]" :key="priceIndex" :class="prd.cls">{{ (price).toFixed(2) }} &euro;</td>
              <td v-for="(price,priceIndex) in prices.rooms.family[prd.type]" :key="priceIndex" :class="prd.cls">{{ (price).toFixed(2) }} &euro;</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 class="mt-5 mt-md-3">
        {{ $t('prices.double') }}
      </h3>
      
        <table class="shadow p-3 mb-5 bg-body rounded table table-striped table-borderless table-hover text-center">
          <thead>
            <tr>
              <th>{{ $t('keywords.period') }}</th>
              <th>1 {{ $t('keywords.person') }}</th>
              <th>{{ $t('keywords.people', {nb: 2}) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prd,index) in period" :key="index">
              <td :class="prd.cls">{{ $t('prices.period.'+prd.type+'['+prd.idx+']') }}</td>
              <td v-for="(price,priceIndex) in prices.rooms.double[prd.type]" :key="priceIndex" :class="prd.cls">{{ (price).toFixed(2) }} &euro;</td>
            </tr>
          </tbody>
        </table>
      
      <h3 class="mt-5 mt-md-3">
       {{ $t('prices.twin') }}
      </h3>
      
        <table class="shadow p-3 mb-5 bg-body rounded table table-striped table-borderless table-hover text-center">
          <thead>
            <tr>
              <th>{{ $t('keywords.period') }}</th>
              <th>1 {{ $t('keywords.person') }}</th>
              <th>{{ $t('keywords.people', {nb: 2}) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prd,index) in period" :key="index">
              <td :class="prd.cls">{{ $t('prices.period.'+prd.type+'['+prd.idx+']') }}</td>
              <td v-for="(price,priceIndex) in prices.rooms.twin[prd.type]" :key="priceIndex" :class="prd.cls">{{ (price).toFixed(2) }} &euro;</td>
            </tr>
          </tbody>
        </table>
      

      <h1 class="mt-5">
        {{ $t('prices.titles[1]') }}
        <small class="d-block d-md-inline text-muted"> /{{ $t('keywords.night') }}</small>
        <small class="d-block text-end text-muted float-md-end breakfastText">{{ $t('keywords.breakfast') }}</small>
      </h1>
      <div class="table-responsive-md">
        <table class="shadow p-3 mb-5 bg-body rounded table table-striped table-borderless table-hover text-center">
          <thead>
            <tr>
              <th> </th>
              <th>{{ $t('campsite.options.summer.period') }}</th>
              <th>{{ $t('campsite.options.winter.period') }} <br>{{ $t('campsite.reduced') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(price,index) in prices.campsite" :key="index">
              <td>{{ $t('campsite.options.'+index) }}</td>
              <td v-for="(pri,priIndex) in price" :key="priIndex" class="">{{ (pri).toFixed(2) }} &euro;</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h1 class="mt-5">
        {{ $t('prices.titles[2]') }}
        <small class="d-block text-end text-muted float-md-end breakfastText">{{ $t('keywords.breakfast') }}</small>
      </h1>
      <div class="table-responsive-md">
        <table class="shadow p-3 mb-5 bg-body rounded table table-striped table-borderless table-hover text-center">
          <thead>
            <tr>
              <th colspan="2"> </th>
              <th colspan="2">{{ $t('prices.lotus') }}</th>
              <th colspan="2">{{ $t('prices.bell') }}</th>
            </tr>
            <tr>
              <th colspan="2">{{ $t('keywords.period') }}</th>

              <th>{{ $t('keywords.night') }}</th>
              <th>{{ $t('keywords.week') }}</th>

              <th>{{ $t('keywords.night') }}</th>
              <th>{{ $t('keywords.week') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prd,index) in glamping_period" :key="index">
              <td colspan="2">{{ $t('glamping.seasons.'+prd.type+'['+prd.idx+']') }}</td>
              <td v-for="(price,priceIndex) in prices.glamping[prd.type]" :key="priceIndex" class="">{{ (price).toFixed(2) }} &euro;</td>
              <td v-for="(price,priceIndex) in prices.glamping[prd.type]" :key="priceIndex" class="">{{ (price).toFixed(2) }} &euro;</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t('keywords.animal') }}</td>
              <td class="">{{ (prices.glamping.animal[0]).toFixed(2) }} &euro;</td>
              <td class="">{{ (prices.glamping.animal[1]).toFixed(2) }} &euro;</td>
              <td class="">{{ (prices.glamping.animal[0]).toFixed(2) }} &euro;</td>
              <td class="">{{ (prices.glamping.animal[1]).toFixed(2) }} &euro;</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import HeroCarousel from '../ui/HeroCarousel.vue';
import Prices from '../../assets/json/prices.json';

export default {
  name: 'PricesPage',
  components: { HeroCarousel },
  computed: {
    period : function() {
      return [
        {type: "low", idx: 0, cls: "table-secondary"},
        {type: "mid", idx: 0, cls: "table-primary"},
        {type: "low", idx: 1, cls: "table-secondary"},
        {type: "mid", idx: 1, cls: "table-primary"},
        {type: "high", idx: 0, cls: "table-success"},
        {type: "mid", idx: 2, cls: "table-primary"},
        {type: "high", idx: 1, cls: "table-success"},
        {type: "mid", idx: 3, cls: "table-primary"},
        {type: "low", idx: 2, cls: "table-secondary"},
        {type: "high", idx: 2, cls: "table-success"}
      ]
    },
    glamping_period : function() {
      return [
        {type: "low", idx: 0},
        {type: "high", idx: 0},
        {type: "low", idx: 1},
      ]
    }
  },
  data() {
    return {
      carouselSlides : [
        'prices_slider'
      ],
      prices : Prices
    }
  }
};
</script>

<style lang="scss">
.prices-page {
  th {
    text-align: center;
  }
  .breakfastText {
    font-size: 16px;
  }
}
</style>
