<template>
<footer>
  <div class="container-xxl">
    <div class="row justify-content-evenly">
      <div class="col-sm-6 col-lg-4 col-xl-3 profile">
        <h3>{{ $t('footer.who') }}</h3>
        <img class="mt-3 img-fluid" :src="require(`@/assets/img/footer_profile.jpg`)" width="" alt="">
        <p class="mt-3">
          {{ $t('footer.description') }}
        </p>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3" style="display:none;">
        <h3>{{ $t('footer.partners') }}</h3>
      </div>
      <div class="col-sm-6 col-lg-4 col-xl-3 social text-center">
        <h3>{{ $t('footer.social') }}</h3>
        <div class="social-link instagram mb-5" style="margin-top: 1.5rem;">
          <a class="" target="_blank" href="https://www.instagram.com/la_source05_/">
            <img class="img-fluid" :src="require(`@/assets/img/social/instagram.png`)" />
          </a>
        </div>
        <div class="social-link youtube mb-5">
          <a class="" target="_blank" href="https://www.youtube.com/channel/UC4hmANUqlD1Cog0dHOOHr5w">
            <img class="img-fluid" :src="require(`@/assets/img/social/youtube.png`)" />
          </a>
        </div>
        <div class="social-link facebook mb-5">
          <a class="" target="_blank" href="https://www.facebook.com/frenchtracksdelasource/">
            <img class="img-fluid" :src="require(`@/assets/img/social/facebook.png`)" />
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        SARL Frenchtracks de La source | {{ $t('footer.rights')}} | Copyright © 2013-{{ year }}
      </div>
    </div>
  </div>
</footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    year : function() {
      let d = new Date();
      return d.getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
  footer {
    padding: 30px 0;
    width: 100%;
    background-color: rgba(51,98,155,1);
    color: white;

    h3 {
      text-align: center;
      font-weight: 600;
      color: white;
    }

    .profile {
      text-align: center;
      color: white;
      p {
        text-align: justify;
      }
    }

    .social {
      a {
        font-size: 40px;
        font-weight: 900;
      }
      img {
        opacity: 0.8;
        transition: opacity 0.3s;
        &:hover {
          opacity: 1;
        }
      }
    }

  }
</style>
