export default {
  "keywords": {
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoon"])},
    "people": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("nb")), " Mensen"])},
    "tents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenten"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
    "animal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacht"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
    "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbijt kan worden besteld voor €9. Brood en croissants kunnen ook worden besteld om mee te nemen."])},
    "freebreakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbijt inbegrepen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamers"])},
    "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping"])},
    "glamping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerij"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])}
  },
  "notfound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina niet gevonden"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina die u opvraagt, bestaat niet."])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de startpagina"])}
  },
  "home": {
    "plane": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wereldkampioenschap ultralichte zweefvliegtuigen wordt gehouden in Aspres-sur-Buëch van 23 juli tot 6 augustus."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier voor meer informatie"])}
    ],
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek op film"])},
    "newDiscover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek onze nieuwe video"])},
    "halloween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boek uw Halloween"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom bij La Source !"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Source ligt aan de noordgrens van de Provence in de regio Hautes Alpes in Frankrijk, tegen de prachtige achtergrond van de Franse Alpen. De Hautes Alpes is de ideale locatie voor een ontspannende vakantie of een actieve vakantie vol activiteiten. De regio biedt een grote verscheidenheid aan buitenactiviteiten voor elk seizoen en het berglandschap is werkelijk ontzagwekkend."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze bossen worden gedomineerd door prachtige exemplaren van mammoetbomen, kersenbomen, dennenbomen, linden, walnotenbomen en vele andere en met gemiddeld 300 dagen zon per jaar is La Source een heerlijke en rustige plek om te ontspannen en tot rust te komen."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met bergen, meren, rivieren en paden onder een uitgestrekte en vaak wolkenloze hemel, biedt de Franse Hautes Alpes een van Europa's onontdekte buitenspeeltuinen. Activiteiten en avonturen zijn er in overvloed en de regio biedt een aantal van de beste mountainbike-, fiets-, winter- en watersporten van Europa."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In samenwerking met lokale bedrijven en deskundige gidsen kunnen we een verscheidenheid aan activiteiten organiseren om ervoor te zorgen dat uw verblijf een onvergetelijke en opwindende ervaring wordt. Van de bescheiden beginner tot de echte avonturier, de Hautes Alpes biedt voor elk wat wils."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De camping met 25 plaatsen is het hele jaar geopend (volledige faciliteiten van half april tot half oktober, beperkte faciliteiten van november tot maart), onze Chambres d'Hotes en ons restaurant zijn het hele jaar door geopend, en zijn ook beschikbaar voor privéfeesten. Dus als u op zoek bent naar een rustige, ontspannende vakantie, een vakantie vol actie of gewoon een kopje thee of een avondmaaltijd, neem dan contact met ons op."])}
    ],
    "useful": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuttige koppelingen"])},
      "links": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gites de France"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cool Camping"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routard"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Devoluy"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meteo France"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toeristische Dienst"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hautes Alpes"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astronomie"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAG Vintage Club Automobiel"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage Sun Run"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skistations La Drome"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La longeagne weer"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspres-sur-buëch luchthaven"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serres luchthaven"])}
      ]
    },
    "cards": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Source 'Aire Naturelle' ligt op 5,5 hectare en biedt zowel open als schaduwrijke kampeerplaatsen, allemaal met een prachtig uitzicht op de bergen."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glamping in onze ingerichte tenten, met alles wat je nodig hebt om te genieten van een alternatieve kampeerervaring."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons eigen restaurant / terras biedt ontbijt, afternoon tea, zelfgemaakt gebak en vers bereide avondmaaltijden."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B & B"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze prachtig ingerichte slaapkamers zijn warm en gastvrij, sommige met prachtig uitzicht op de bergen."])}
      }
    ]
  },
  "campsite": {
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek onze camping"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het hele jaar geopend"])},
    "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Per nacht)"])},
    "reduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Verminderde diensten)"])},
    "options": {
      "summer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zomer Prijzen"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April-Oktober"])}
      },
      "winter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Prijzen"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November-maart"])}
      },
      "pitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standplaats"])},
      "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volwassenen"])},
      "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind jonger dan 10"])},
      "electricity6amp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteit 6 amp"])},
      "electricity10amp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteit 10 amp"])},
      "animal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal"])},
      "extratent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleine extra tent"])},
      "extracar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Supplement"])},
      "extrapers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezoeker per dag"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toeristenbelasting"])}
    },
    "services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diensten"])},
      "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis WiFi-toegang"])},
      "shower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis warme douches en toiletten"])},
      "bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers brood / Croissants"])},
      "fridge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis Openbare Koelkast/Vriezer"])},
      "washing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasmachine 5€"])},
      "tennis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tafeltennis"])},
      "volley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badminton/Volleybal Net"])},
      "picnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picknickbanken"])},
      "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Navulling"])},
      "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvalverwijdering"])},
      "winter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beschikbaar in de winter"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wakker worden met de zon die opkomt over de bergen markeren de herten die rondzwerven in de ochtendmist."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U komt La Source binnen via onze met bomen omzoomde oprijlaan en verscholen achter volwassen bossen, vindt u binnen onze 2,2 hectare prachtige bomen en graslanden 25 ruime kampeerplaatsen op onze ongeveer 11.000m² grote camping die een rustige plek bieden om te ontspannen na een lange dag deel te hebben genomen aan een van de vele activiteiten die onze bergachtige streek te bieden heeft. Neem een kijkje op onze plattegrond."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For guests with babies, we have a baby changing mat and a high chair. We also have board games, tennis rackets and a ping-pong table. For the adventurous, we also have 3 bikes. Subject to availability."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor gasten met baby's hebben we een aankleedkussen en een kinderstoel. We hebben ook bordspellen, tennisrackets en een pingpongtafel. Voor de avonturiers hebben we ook 3 fietsen. Onder voorbehoud van beschikbaarheid."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons voorveld is een vrije ruimte waar iedereen van kan genieten - een picknick, een partijtje voetbal, speciale evenementen of zelfs het landen van je Parapente."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons Restaurant/Terras biedt ijskoude drankjes, zelfgemaakt gebak en koekjes voor bij de thee/koffie. Wij serveren ook ontbijt en vers bereide avondmaaltijden."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnen ons etablissement beschikken wij over een PRM-toilet en -wasbak die 24 uur per dag toegankelijk zijn."])}
    ],
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plattegrond"])},
    "planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red onze planeet! Wij recyclen! Er zijn vuilnisbakken voor plastic, glas en papier; We vragen onze gasten om geen water te verspillen of afval achter te laten op de camping en we moedigen onze gasten aan om eco-producten te gebruiken."])},
    "darkSky": {
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek Dark Sky Camping"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Sky Camping"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je op zoek bent naar een echt unieke kampeerervaring, kijk dan niet verder dan La Source. Onze camping bevindt zich in een gebied zonder lichtvervuiling. Dit betekent dat je de nachtelijke hemel in al zijn glorie kunt zien, met sterren, sterrenstelsels en constellaties die duidelijk zichtbaar zijn. Maar de voordelen van verblijven op een donkere hemel camping gaan verder dan alleen sterren kijken. Lees verder, we zullen de voordelen van kamperen op een plek waar de hemel echt donker is verkennen."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een van de belangrijkste attracties van een donkere hemel camping is de mogelijkheid om de sterren als nooit tevoren te zien. In Saint Pierre d'Argençon hebben we minimale lichtvervuiling, dus de nachtelijke hemel wordt een canvas van twinkelingen sterren, planeten en andere hemellichamen. Je ziet de Melkweg, vallende sterren en constellaties op een manier die onmogelijk is in steden of andere gebieden met veel lichtvervuiling. Sterren kijken op een donkere hemel camping is een echt indrukwekkende ervaring die je je hele leven zult herinneren."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer u zich op een plek bevindt met minimale lichtvervuiling, is de natuurlijke slaapcyclus van uw lichaam minder vatbaar voor verstoring. Blootstelling aan kunstmatig licht 's nachts kan interfereren met de productie van melatonine, het hormoon dat de slaap reguleert. Wanneer u kampeert op een plek waar het enige licht van de sterren en de maan komt, kan uw lichaam gemakkelijker synchroniseren met zijn natuurlijke circadiane ritme. Dit betekent dat u waarschijnlijk beter zult slapen en wakker zult worden, uitgerust en verfrist."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een verblijf in de Hautes Alpes zal u helpen om een diepere verbinding met de natuur te maken. Zonder de afleiding van kunstmatig licht kunt u volledig ondergedompeld worden in de natuurlijke schoonheid van de regio. U kunt luisteren naar de geluiden van nachtdieren, frisse lucht inademen en de vrede en rust voelen van weg zijn van de drukte van de beschaving. Dit kan een geweldige manier zijn om los te koppelen van technologie, opnieuw te focussen en een nieuwe waardering te ontwikkelen voor de natuurlijke wereld."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je op zoek bent naar een unieke en onvergetelijke kampeerervaring, overweeg dan een verblijf op een donkere hemel camping, zoals La Source. Met verbeterd sterrenkijken, verbeterde slaap en verhoogde waardering voor de natuur is het een ervaring die moeilijk te overtreffen is. Dus pak je tent in, neem je slaapzak mee of boek een verblijf in een van onze Glamping-tenten en reserveer een verblijf in La Source voor een onvergetelijk avontuur onder de sterren."])}
      ],
      "headings": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeterde sterrenobservatie"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeterde slaap"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhoogde waardering van de natuur"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveer een avontuur onder de sterren bij La Source"])}
      ]
    }
  },
  "rooms": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kamer ", _interpolate(_named("id"))])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamers"])},
    "room": [
      {
        
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Persoon"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begane grond"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingsize Bed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensuite"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwaak met een prachtig uitzicht op het Jouffangebergte en neem het ontbijt op uw eigen privéterras."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruime kamer voor 2 personen op de begane grond met kingsize tweepersoonsbed en en-suite douche en eigen toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tafel en stoelen, kleerkast en ladekast. Beddengoed, handdoeken en ontbijt zijn bij de prijs inbegrepen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De openslaande deuren bieden extra toegang met uitzicht op het bos en het Jouffan-gebergte dat boven La Source uittorent."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2/3 Mensen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Mensen"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begane grond"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Mensen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingsize Bed & Stapelbed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensuite"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grote familie kamer op de begane grond met en-suite en eigen toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een kingsize tweepersoonsbed, een groot stapelbed, een flatscreen tv/dvd met films en veel kinderspelletjes voor het geval u een nachtje wilt blijven slapen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrische verwarming, tafel en stoelen, kleerkast en ladekast."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beddengoed, handdoeken en ontbijt zijn inbegrepen in de prijs."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Persoon"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste etage"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingsize Bed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensuite"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruime ensuite kamer op de eerste verdieping met kingsize tweepersoonsbed en eigen toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prachtig uitzicht op het bos en het Jouffangebergte dat boven La Source uittorent."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrische verwarming, tafel en stoelen, kleerkast en ladekast."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beddengoed, handdoeken en ontbijt zijn inbegrepen in de prijs."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Persoon"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste etage"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 eenpersoonsbedden"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toilet"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruime kamer op de eerste verdieping met twee eenpersoonsbedden, kaptafel/stoel, kledingkast en wastafel."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beddengoed, handdoeken en ontbijt zijn inbegrepen in de prijs."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer 4 en 5 kunnen samen geboekt worden als een familiekamer voor 4 tot 6 personen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact met ons op."])}
        ]
      },
      {
        "people": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Persoon"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])}
        ],
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste etage"])},
        "content": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Mensen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweepersoonsbed"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aparte doucheruimte"])}
        ],
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grote kamer op de eerste verdieping met tweepersoonsbed, een extra eenpersoonsbed kan op verzoek worden toegevoegd. comfortabele bank, kledingkast en wastafel. Aparte doucheruimte en toilet."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beddengoed, handdoeken en ontbijt zijn inbegrepen in de prijs."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer 4 en 5 kunnen samen geboekt worden als een familiekamer voor 4 tot 6 personen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact met ons op."])}
        ]
      }
    ],
    "seasons": {
      "highlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogseizoen"])},
      "midlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middenseizoen"])},
      "lowlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laagseizoen"])},
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 May - 31 May"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juli - 31 Aug"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Dec - 3 Jan"])}
      ],
      "mid": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Feb - 29 Feb"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Apr - 30 Apr"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juni - 30 Juni"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sep - 31 Okt"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Jan - 31 Jan"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Maa - 31 Maa"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Nov - 20 Dec"])}
      ]
    }
  },
  "glamping": {
    "nav": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentatie"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel Tent 1"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel Tent 2"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel Tent 3"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stargazer Tent 1"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stargazer Tent 2"])}
    ],
    "lotus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lotus Belle 'Stargazer' Tent ", _interpolate(_named("id"))])},
    "seasons": {
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli-Augustus"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April-Juni"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September-Oktober"])}
      ]
    },
    "presentation": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verscholen in de bossen rond La Source vindt u onze prachtig ingerichte Sibley Bell Tenten en Lotus Belle Stargazer Tenten, elk gelegen op een houten vlonder om een vlakke stabiele basis te garanderen - elk met zijn eigen individuele uitzicht op de natuur. Onze 3 bel tenten hebben een indrukwekkend uitzicht op de bergen en onze 2 Stargazers liggen midden in onze prachtige bossen."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze comfortabel ingerichte Bell Tents voor 2 personen, bieden allemaal echte tweepersoonsbedden en meubilair, beddengoed, houten vloeren, opslag en nachtkastjes voor kleding en persoonlijke spullen, tapijten, waterkoker, elektrische koelbox / koelkast, elektrische verlichting, gas BBQ, tafel en stoelen op het terras en alles wat u nodig heeft om te genieten van een alternatieve Glamping ervaring. Handdoeken en ontbijt niet inbegrepen in de prijs."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons Restaurant/Terras biedt ijskoude drankjes, zelfgemaakt gebak en koekjes voor bij de thee/koffie. Wij serveren ook ontbijt en vers bereide avondmaaltijden, vooraf te reserveren."])}
    ]
  },
  "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de zomer van 2016 openden we ons eigen kleine restaurant als aanvulling op ons zeer populaire terras met een prachtig majestueus uitzicht op de bergen. Er is iets surrealistisch over het kijken naar de zon die ondergaat achter de bergen terwijl u geniet van uw aperitief of ijskoud bier, wijn of cocktail. Het restaurant biedt ontbijt, thee/koffie met zelfgemaakt gebak en vers bereide huisgemaakte avondmaaltijden. Ons restaurant is het hele jaar door geopend, en beschikbaar voor kleine prive-functies etc. Alleen op reservering."])},
  "prices": {
    "titles": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen Bed & Ontbijt 2024"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping prijzen 2024"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel Tents & Lotus Belle 'StarGazer' prijzen 2024"])}
    ],
    "ensuite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer met badkamer (Ensuite)"])},
    "double": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer met badkamer (NIET Ensuite)"])},
    "twin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer met wastafel"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lotus Tents"])},
    "bell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel Tents"])},
    "period": {
      "highlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogseizoen"])},
      "midlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middenseizoen"])},
      "lowlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laagseizoen"])},
      "high": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 May - 31 May"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juli - 31 Aug"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Dec - 3 Jan"])}
      ],
      "mid": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Feb - 29 Feb"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Apr - 30 Apr"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Juni - 30 Juni"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sep - 31 Okt"])}
      ],
      "low": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Jan - 31 Jan"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Maa - 31 Maa"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Nov - 20 Dec"])}
      ]
    }
  },
  "gallery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerij"])},
    "filters": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteiten"])},
      "lasource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Source"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lokale zone"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natuur"])},
      "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])}
    }
  },
  "events": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])}
  },
  "contact": {
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiel"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "gps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS coördinaten"])},
    "by": {
      "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per vliegtuig"])},
      "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per auto"])},
      "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per trein"])},
      "airdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Pierre d'Argençon is centraal gelegen tussen Lyon, Grenoble, Chambery en Marseille, tussen 90 minuten en 2 uur rijden. Dit zouden onze voorkeur luchthavens zijn. Verder a veld zijn Nice en Genève."])},
      "cardesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Haute Alpes is gemakkelijk te bereiken vanuit de meeste richtingen, we zijn ongeveer 10 uur rijden van Calais. De snelweg route is relatief rechtdoor met veel rustpunten langs de weg. Voer Saint Pierre d'Argençon, 05140 in AA Autoroute of ViaMichelin."])},
      "traindesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurostar van Londen St Pancreas naar Parijs en dan de TGV van Parijs naar Grenoble. (ongeveer 3 uur) Neem dan een lokale trein van Grenoble naar Aspres-sur-Buêch dat op 5 km afstand ligt van Saint Pierre d'Argençon."])}
    },
    "useful": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuttige koppelingen"])},
      "links": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy Jet"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Airways"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flybe"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bmibaby"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryan Air"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AA"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Michelin"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurostar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TGV"])}
      ]
    }
  },
  "footer": {
    "who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie we zijn"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze partners"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socialiseer !"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle rechten gereserveerd"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo, wij zijn Paul en Judy, wij zijn in 2004 met onze twee kinderen vanuit het Verenigd Koninkrijk naar Frankrijk verhuisd en hebben deze streek gekozen vanwege de adembenemende schoonheid, de frisse lucht en het heerlijke weer. De Hautes Alpes is als een kant-en-klare avonturenspeeltuin met zo veel activiteiten in zowel zomer als winter."])}
  }
}